import {createGlobalStyles} from "goober/global";
import {StyledFunction, StyledVNode} from "goober";

export const GlobalStyles = createGlobalStyles`
  :root {
        --sat: env(safe-area-inset-top);
        --sar: env(safe-area-inset-right);
        --sab: env(safe-area-inset-bottom);
        --sal: env(safe-area-inset-left);
      }

    *, *::before, *::after {
      box-sizing: border-box;
    } 
   
    html {
      height: 100%;
      font-size: clamp(1rem, 1.17vw + 0.25rem, 1.19rem);
      padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    }
    
    *:not(.fa-solid):not(.fa):not(.fas):not(.far):not(.fal):not(.fat):not(.fab) {
      font-family: 'Noto Sans', sans-serif;
    }
    
    // div, button, input, textarea, select {
    //     font-size: 1rem;
    // }
    
    body {
      margin: 0;
      min-height: 100%;
      height: 100%;
    }

      #root {
        height: 100%;
      }
      
      .hg-theme-default {
        background-color: #cccccc;
      }
      
      .my-custom-keyboard .hg-button {
            font-size: 1.5rem; 
            height: 6vh;
            margin: 0.5rem 0 0 0;
        }
      .my-custom-keyboard .hg-row:nth-child(2) {
        margin-left: 18px;
        margin-right: 18px;
        }
        .my-custom-keyboard .hg-button-space {
          max-width: 448px;
        }
        .my-custom-keyboard .hg-button-enter {
          max-width: 110px;
        }
        .my-custom-keyboard .hg-button-altright,
        .my-custom-keyboard .hg-button-back {
          min-width: 80px;
          max-width: 80px;
        }        
    ` as StyledVNode<any>;
