import {h} from 'preact';
import {styled} from "goober";
import {authApiClient} from "../globalDependencies";
import {route} from "preact-router";
import {useContext, useEffect, useState} from "preact/hooks";
import {BackIcon, ForwardIcon, MenuButton, MenuSecondaryButton} from "./ui/Button";
import ControlledCheckbox from "./ui/controlledCheckbox";
import {forwardRef} from "preact/compat";
import {subscribeToPushNotifications} from "../lib/pushNotifications";
import {ApiClient} from "../api/realApiClient";
import {SubTitle, Title} from "./ui/Text";
import {ResponsiveContainer, TopFlowContainer} from "./ui/Container";
import {GlobalContext} from "../GlobalContext";
import VSpace from "./ui/VSpace";
import {storeUserConfiguration} from "../lib/localUserConfiguration";
import {Trans, useTranslation} from 'react-i18next';
import UILanguageScreen from "./UILanguageScreen";
import SchoolYearScreen from "./SchoolYearScreen";
import SchoolCountryScreen from "./SchoolCountryScreen";

const Header = styled('div')`
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 18px;
    border-bottom: 1px solid #e6e6e6;
`;

const ProfileSection = styled('div')`
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
`;

const ProfileInfo = styled('div')`
    line-height: 1.4;
`;

const UserName = styled('div')`
    color: ${props => props.theme.pen};
    font-weight: bold;
`;

const ViewProfile = styled('div')`
    color: ${props => props.theme.pen};
`;

const Section = styled('div')`
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${props => props.theme.pen};
`;

const SectionItem = styled('div', forwardRef)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
`;

const Icon = styled('i')`

`;

const ProfileIcon = styled(Icon)`
    border-radius: 50%;
    margin-right: 16px;
`;

const MenuSecondaryDangerButton = styled(MenuSecondaryButton)`
    background-color: ${props => props.theme.paper};
    color: ${props => props.theme.correctionColor};
`;

const UserIcon = () => <ProfileIcon className="fa-solid fa-user fa-2xl"/>;

enum PushNotificationState {
    NotAvailable,
    Denied,
    Allowed
}

enum SettingScreenState {
    General,
    SchoolCountry,
    SchoolYear,
    UILanguage
}

const SettingsScreen = () => {
    const {t} = useTranslation();
    const {user} = useContext(GlobalContext);
    const username = user?.nickname;

    const logout = async () => {
        if (await authApiClient.logout()) {
            route('/login');
        }
    }

    const notifications = window.Notification;
    const [settingScreenState, setSettingScreenState] = useState<SettingScreenState>(SettingScreenState.General);
    const [pushNotifications, setPushNotifications] = useState(notifications ? (notifications.permission === 'granted' ? PushNotificationState.Allowed : PushNotificationState.Denied) : PushNotificationState.NotAvailable);
    const [userConfiguration, setUserConfiguration] = useState(useContext(GlobalContext).userConfiguration);

    useEffect(() => {
        console.log('Component mounted');
        return () => {
            console.log('Component will unmount');
        };
    }, []);

    useEffect(() => {
        storeUserConfiguration(userConfiguration);
    }, [userConfiguration]);

    const requestPermission = async () => {
        if (!notifications) {
            console.log('window.Notification not available');
            return false;
        }
        const result = notifications.permission;
        if (result !== 'granted') {
            const permission = await window.Notification.requestPermission();
            return permission === 'granted';
        }
        return true;
    };

    const pushNotificationsChanged = async (userWantsToCheck: boolean) => {
        if (userWantsToCheck) {
            const granted = await requestPermission();
            if (!granted) {
                setPushNotifications(PushNotificationState.NotAvailable);
            } else {
                setPushNotifications(PushNotificationState.Allowed);
                const subscription = await subscribeToPushNotifications();
                if (subscription) {
                    await ApiClient.createOrUpdateWebPushNotification(subscription);
                }
            }
        } else {
            setPushNotifications(PushNotificationState.Denied);
            await ApiClient.deleteWebPushNotification();
        }
    };

    if (settingScreenState === SettingScreenState.UILanguage) {
        return <UILanguageScreen onContinue={() => setSettingScreenState(SettingScreenState.General)}/>
    } else if (settingScreenState === SettingScreenState.SchoolYear) {
        return <SchoolYearScreen currentYear={userConfiguration.schoolYear} onContinue={
            (selected) => {
                setUserConfiguration(prev => ({
                    ...prev,
                    schoolYear: selected
                }));
                setSettingScreenState(SettingScreenState.General);
            }
        }/>
    } else if (settingScreenState === SettingScreenState.SchoolCountry) {
      return <SchoolCountryScreen currentCountry={userConfiguration.schoolCountry} onContinue={
            (selected) => {
                setUserConfiguration(prev => ({
                    ...prev,
                    schoolCountry: selected
                }));
                setSettingScreenState(SettingScreenState.General);
            }
      }/>
    } else if (settingScreenState === SettingScreenState.General) {
        return (
            <ResponsiveContainer>
                <TopFlowContainer>
                    <Header>
                        <BackIcon onclick={() => route('/')}/>
                        <Title>{t('settings.title')}</Title>
                    </Header>
                    <ProfileSection>
                        <UserIcon/>
                        <ProfileInfo>
                            <ViewProfile>{t('settings.username')}</ViewProfile>
                            <UserName>{username}</UserName>
                        </ProfileInfo>
                    </ProfileSection>
                    <Section>
                        <SubTitle>{t('settings.general')}</SubTitle>
                        <SectionItem onclick={() => setSettingScreenState(SettingScreenState.SchoolCountry)}>
                            <span>{t('settings.choose_school_country')}</span>
                            <ForwardIcon onclick={() => setSettingScreenState(SettingScreenState.SchoolCountry)}/>
                        </SectionItem>

                        <SectionItem onclick={() => setSettingScreenState(SettingScreenState.SchoolYear)}>
                            <span>{t('settings.choose_school_year')}</span>
                            <ForwardIcon onclick={() => setSettingScreenState(SettingScreenState.SchoolYear)}/>
                        </SectionItem>

                        <ControlledCheckbox id={'asd'} label={t('settings.receive_notifications')}
                                            checked={pushNotifications === PushNotificationState.Allowed}
                                            onClick={(_) => pushNotificationsChanged(_)}
                                            disabled={pushNotifications === PushNotificationState.NotAvailable}/>
                    </Section>
                    <Section>
                        <SubTitle>{t('settings.maths.title')}</SubTitle>
                        <SectionItem>
                            <ControlledCheckbox id={'foxx'} label={t('settings.maths.prefer_cross')}
                                                checked={userConfiguration.maths.preferMultiplicationCrossOverDot}
                                                onClick={(_) => setUserConfiguration(prev => ({
                                                    ...prev,
                                                    maths: {
                                                        ...prev.maths,
                                                        preferMultiplicationCrossOverDot: _
                                                    }
                                                }))}/>
                        </SectionItem>
                        <SectionItem>
                            <ControlledCheckbox id={'foxxi'} label={t('settings.maths.prefer_obelus')}
                                                checked={userConfiguration.maths.preferDivisionObelusOverSlash}
                                                onClick={(_) => setUserConfiguration(prev => ({
                                                    ...prev,
                                                    maths: {
                                                        ...prev.maths,
                                                        preferDivisionObelusOverSlash: _
                                                    }
                                                }))}/>
                        </SectionItem>
                    </Section>
                    <Section>
                        <SectionItem onclick={() => setSettingScreenState(SettingScreenState.UILanguage)}>
                            <span>{t('settings.ui_language')}</span>
                            <ForwardIcon onclick={() => setSettingScreenState(SettingScreenState.UILanguage)}/>
                        </SectionItem>
                    </Section>
                    <Section>
                        <SectionItem onclick={() => route(`/activity/${username}`)}>
                            <span>{t('settings.activities')}</span>
                            <ForwardIcon onclick={() => route(`/activity/${username}`)}/>
                        </SectionItem>
                    </Section>
                    <VSpace space={2}/>
                    <MenuButton onClick={logout}>{t('settings.logout')}</MenuButton>
                    <MenuSecondaryDangerButton>{t('settings.delete_account')}</MenuSecondaryDangerButton>
                </TopFlowContainer>
            </ResponsiveContainer>
        );
    } else {
        return null;
    }
}

export default SettingsScreen;
