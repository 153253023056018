import {backendCreateAccountErrorMessages, backendLoginErrorMessages} from "../auth";
import {CreateUserAccountRequest} from "../auth/createUserAccountRequest";
import {LoginAccountRequest} from "../auth/loginAccountRequest";

export enum LoginFailReason {
    InvalidUsername,
    InvalidPassword,
}

export enum CreateAccountFailReason {
    InvalidUsername,
    InvalidPassword,
    UserAlreadyExists
}

export interface AuthApiClient {
    hasValidAccessToken(): boolean;

    getCurrentAccessToken(): string;

    getCurrentIdToken(): string;

    getCurrentOrNewAccessToken(): Promise<string>;

    getCurrentOrNewIdToken(): Promise<string>;

    getHeaderWithContentType(): Promise<{ headers: { 'Content-Type': string, Authorization: string } }>;

    getAuthStatus(): Promise<boolean>;

    createGuestAccount(playerName?: string): Promise<void>;

    createAccount(createUserAccountRequest: CreateUserAccountRequest): Promise<{
        failedReason?: CreateAccountFailReason
    }>;

    loginAccount(loginAccountRequest: LoginAccountRequest): Promise<{ failedReason?: LoginFailReason }>;

    logout(): Promise<boolean>;
}

export function getEnvironment() {
    // @ts-ignore
    if (typeof process !== 'undefined' && process.versions && process.versions.node) {
        return 'node';
    } else if (typeof window !== 'undefined' && window.document) {
        return 'browser';
    } else {
        return 'unknown';
    }
}

export function getTokenPayload<T>(token: string): T {
    // Split the token into header, payload, and signature
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_header, payload, _signature] = token.split('.');

    // Decode the payload (base64)
    const decodedPayload = atob(payload);

    // Parse the payload as JSON
    return JSON.parse(decodedPayload);
}

export function createAuthApiClient(serverUrl: string): AuthApiClient {
    let accessToken: string | null = null;
    let idToken: string | null = null;
    let httpOnlyRefreshToken: string | undefined;
    const isBackend = (getEnvironment() === 'node');

    // dont allow concurrent refresh token requests; delay all other requests until the first one is done

    function isTokenValid(token: string) {
        try {
            // Split the token into header, payload, and signature
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_header, payload, _signature] = token.split('.');

            // Decode the payload (base64)
            const decodedPayload = atob(payload);

            // Parse the payload as JSON
            const payloadObj = JSON.parse(decodedPayload);

            // Get the current time
            const currentTime = Math.floor(Date.now() / 1000);

            // Check if the token has expired
            return (payloadObj.exp + 60) >= currentTime;

            // Token is still valid
        } catch (error) {
            return false; // Return false if there's an error
        }
    }

    // if I dont have a valid access token, I need to refresh it but I dont know whether I have a valid refresh token
    // until I talked to the backend
    function hasValidAccessToken() {
        return accessToken !== null && isTokenValid(accessToken);
    }

    function getCurrentAccessToken() {
        if (accessToken === null || !isTokenValid(accessToken)) {
            throw new Error('No access token or invalid access token');
        }
        return accessToken;
    }

    function getCurrentIdToken() {
        if (idToken === null || !isTokenValid(idToken)) {
            throw new Error('No id token or invalid id token');
        }
        return idToken;
    }

    async function getCurrentOrNewAccessToken(): Promise<string> {
        return (await getCurrentNewTokens()).accessToken;
    }

    async function getCurrentOrNewIdToken(): Promise<string> {
        return (await getCurrentNewTokens()).idToken;
    }

    async function getCurrentNewTokens(): Promise<{ accessToken: string, idToken: string }> {
        if (accessToken && isTokenValid(accessToken) && idToken) {
            return {accessToken, idToken};
        }
        const result = await refreshToken();
        setAccessToken(result.accessToken, result.idToken);
        return {accessToken: result.accessToken, idToken: result.idToken};
    }


    function setAccessToken(atoken: string, iToken: string) {
        accessToken = atoken;
        idToken = iToken;
    }

    // if we are in the backend we need to add the refresh token to the header which is handled as a http only cookie
    function addHttpOnlyToHeader(headers?: any) {
        if (isBackend) {
            if (!headers) {
                // eslint-disable-next-line no-param-reassign
                headers = {};
            }
            headers.Cookie = httpOnlyRefreshToken!;
        }
        return headers;
    }

    async function refreshToken(): Promise<{ accessToken: string, idToken: string }> {

        const response = await fetch(`${serverUrl}/auth/refresh`, {
            method: 'POST',
            credentials: 'include',
            headers: addHttpOnlyToHeader(),
        });
        if (response.status === 201) {
            const json = await response.json() as { accessToken: string, idToken: string };
            return {accessToken: json.accessToken, idToken: json.idToken};
        } else {
            throw new Error('Failed to refresh token');
        }
    }

    async function getHeaderWithContentType() {
        return {
            headers: {
                // eslint-disable @typescript-eslint/naming-convention
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getCurrentOrNewAccessToken()}`,
            },
        };
    }

    async function getHeader() {
        return {
            headers: {
                Authorization: `Bearer ${await getCurrentOrNewAccessToken()}`,
            },
        };
    }

    async function getAuthStatus(): Promise<boolean> {
        const response = await fetch(`${serverUrl}/auth/status`, {
            method: 'GET',
            credentials: 'include',
            headers: addHttpOnlyToHeader(),
        });
        return response.status === 200 && (await response.json()).status === 'Authenticated';
    }

    async function createGuestAccount(playerName?: string): Promise<void> {
        const response = await fetch(`${serverUrl}/auth/createGuestAccount`, {
            method: 'POST',
            headers: addHttpOnlyToHeader({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({username: playerName ?? null}),
            credentials: 'include',
        });
        if (isBackend) {
            const setCookieHeader = response.headers.get('set-cookie');
            httpOnlyRefreshToken = setCookieHeader!;
        }
        if (response.status === 201) {
            const json = await response.json() as { accessToken: string, idToken: string };
            if (json.accessToken && json.idToken) {
                setAccessToken(json.accessToken, json.idToken);
            }
            return;
        } else {
            throw new Error('Failed to create session account');
        }
    }

    async function createAccount(createDomesticAccountRequest: CreateUserAccountRequest): Promise<{
        failedReason?: CreateAccountFailReason
    }> {
        const response = await fetch(`${serverUrl}/auth/createAccount`, {
            method: 'POST',
            headers: addHttpOnlyToHeader({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(createDomesticAccountRequest),
            credentials: 'include',
        });
        if (isBackend) {
            const setCookieHeader = response.headers.get('set-cookie');
            httpOnlyRefreshToken = setCookieHeader!;
        }
        if (response.status === 201) {
            const json = await response.json() as { accessToken: string, idToken: string };
            if (json.accessToken && json.idToken) {
                setAccessToken(json.accessToken, json.idToken);
            }
            return {};
        } else {
            const json = await response.json() as { error: string };
            switch (json.error) {
                case backendCreateAccountErrorMessages.userAlreadyExists:
                    return {failedReason: CreateAccountFailReason.UserAlreadyExists};
                case backendCreateAccountErrorMessages.invalidUsername:
                    return {failedReason: CreateAccountFailReason.InvalidUsername};
                case backendCreateAccountErrorMessages.invalidPassword:
                    return {failedReason: CreateAccountFailReason.InvalidPassword};
                default:
                    throw new Error('Failed to login domestic account');
            }
        }
    }

    async function loginAccount(loginDomesticAccountRequest: LoginAccountRequest): Promise<{
        failedReason?: LoginFailReason
    }> {
        const response = await fetch(`${serverUrl}/auth/loginAccount`, {
            method: 'POST',
            headers: addHttpOnlyToHeader({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(loginDomesticAccountRequest),
            credentials: 'include',
        });
        if (isBackend) {
            const setCookieHeader = response.headers.get('set-cookie');
            httpOnlyRefreshToken = setCookieHeader!;
        }
        if (response.status === 200) {
            const json = await response.json() as { accessToken: string, idToken: string };
            if (json.accessToken && json.idToken) {
                setAccessToken(json.accessToken, json.idToken);
            }
            return {};
        } else {
            const json = await response.json() as { error: string };
            switch (json.error) {
                case backendLoginErrorMessages.userDoesNotExist:
                case backendLoginErrorMessages.invalidUsername:
                case backendLoginErrorMessages.usernameOrEmailOrPhoneRequired:
                case backendLoginErrorMessages.usernameRequired:
                    return {failedReason: LoginFailReason.InvalidUsername};
                case backendLoginErrorMessages.invalidPassword:
                    return {failedReason: LoginFailReason.InvalidPassword};
                default:
                    throw new Error('Failed to login domestic account');
            }
        }
    }

    async function logout(): Promise<boolean> {
        const response = await fetch(`${serverUrl}/auth/logout`, {
            method: 'POST',
            ...await getHeader(),
            credentials: 'include',
        });
        return response.status === 200;
    }

    return {
        hasValidAccessToken,
        getCurrentAccessToken,
        getCurrentIdToken,
        getCurrentOrNewAccessToken,
        getCurrentOrNewIdToken,
        getAuthStatus,
        getHeaderWithContentType,
        createGuestAccount,
        createAccount,
        loginAccount,
        logout,
    };
}
