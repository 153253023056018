{
  "button_continue": "Continue",
  "school_year": "Year",
  "countries": {
    "germany": "Germany",
    "united_kingdom": "United Kingdom",
    "united_states": "United States"
  },
  "settings": {
    "title": "Settings",
    "username": "Username",
    "general": "General",
    "receive_notifications": "Receive Notifications",
    "choose_school_year": "School Year",
    "choose_school_country": "School Country",
    "maths": {
      "title": "Maths",
      "prefer_cross": "Prefer Cross × for Multiplication",
      "prefer_obelus": "Prefer Obelus ÷ for Division"
    },
    "ui_language": "UI Language",
    "activities": "Activities",
    "logout": "Logout",
    "delete_account": "Delete Account"
  },
  "ui_language": {
    "title": "UI Language",
    "british_english": "British English",
    "american_english": "American English",
    "german": "German"
  },
  "maths": {
    "title": "Mathematics",
    "year1": {
      "text_tasks": {
        "title": "Story Problem",
        "addition_task1": "Kevin has $t(maths.year1.text_tasks.apples, {\"count\": {{initialAmount}} }). His friend Nina gives him {{addAmount}} more $t(maths.year1.text_tasks.apples_sta, {\"count\": {{addAmount}} }). How many apples does Kevin have now?",
        "apples": "{{count}} apple",
        "apples_other": "{{count}} apples",
        "apples_sta": "apple",
        "apples_sta_other": "apples",
        "addition_task2": "Maya finds $t(maths.year1.text_tasks.seashells, {\"count\": {{initialAmount}} }) on the beach. She finds {{addAmount}} more $t(maths.year1.text_tasks.seashells_sta, {\"count\": {{addAmount}} }) later. How many seashells does Maya have in total?",
        "seashells": "{{count}} seashell",
        "seashells_other": "{{count}} seashells",
        "seashells_sta": "seashell",
        "seashells_sta_other": "seashells",
        "addition_task3": "Alex has $t(maths.year1.text_tasks.stickers, {\"count\": {{initialAmount}} }). He receives $t(maths.year1.text_tasks.stickers, {\"count\": {{addAmount}} }) stickers from his teacher as a reward. How many stickers does Alex have altogether?",
        "stickers": "{{count}} sticker",
        "stickers_other": "{{count}} stickers",
        "addition_task4": "Zoe has $t(maths.year1.text_tasks.beads, {\"count\": {{initialAmount}} }). She buys {{addAmount}} more beads to make a necklace. What is the total number of beads Zoe has?",
        "beads": "{{count}} bead",
        "beads_other": "{{count}} beads",
        "addition_task5": "Liam has $t(maths.year1.text_tasks.toyCars, {\"count\": {{initialAmount}} }). His cousin gives him {{addAmount}} more toy cars on his birthday. How many toy cars does Liam have now?",
        "toyCars": "{{count}} toy car",
        "toyCars_other": "{{count}} toy cars",
        "addition_task6": "Aiden has $t(maths.year1.text_tasks.pencils, {\"count\": {{initialAmount}} }). He finds {{addAmount}} more pencils in his desk drawer. How many pencils does Aiden have altogether?",
        "pencils": "{{count}} pencil",
        "pencils_other": "{{count}} pencils",
        "addition_task7": "Olivia has $t(maths.year1.text_tasks.balloons, {\"count\": {{initialAmount}} }). 4 more balloons are given to her at the party. How many balloons does Olivia have in total?",
        "balloons": "{{count}} balloon",
        "balloons_other": "{{count}} balloons",
        "addition_task8": "Lucas has $t(maths.year1.text_tasks.chocolateBars, {\"count\": {{initialAmount}} }). His brother shares {{addAmount}} more chocolate bars with him. How many chocolate bars does Lucas have now?",
        "chocolateBars": "{{count}} chocolate bar",
        "chocolateBars_other": "{{count}} chocolate bars",
        "addition_task9": "Emma has $t(maths.year1.text_tasks.books, {\"count\": {{initialAmount}} }). She goes to the library and borrows {{addAmount}} more books. How many books does Emma have altogether?",
        "books": "{{count}} book",
        "books_other": "{{count}} books",
        "addition_task10": "Ethan has $t(maths.year1.text_tasks.candyPieces, {\"count\": {{initialAmount}} }). He gets {{addAmount}} more pieces from his friend. How many pieces of candy does Ethan have now?",
        "candyPieces": "{{count}} piece of candy",
        "candyPieces_other": "{{count}} pieces of candy",
        "subtraction_task1": "Kevin has {{initialAmount}} cookies. He eats {{subtractAmount}} cookies after lunch. How many cookies does Kevin have left?",
        "subtraction_task2": "Maya has {{initialAmount}} crayons. She gives {{subtractAmount}} crayons to her friend. How many crayons does Maya have now?",
        "subtraction_task3": "Alex has {{initialAmount}} marbles. He loses {{subtractAmount}} marbles while playing. How many marbles does Alex have left?",
        "subtraction_task4": "Zoe has {{initialAmount}} hair clips. She gives {{subtractAmount}} hair clips to her sister. What is the total number of hair clips Zoe has now?",
        "subtraction_task5": "Liam has {{initialAmount}} stickers. He uses {{subtractAmount}} stickers to decorate his notebook. How many stickers does Liam have left?",
        "subtraction_task6": "Aiden has {{initialAmount}} pencils. He gives {{subtractAmount}} pencils to his classmates. How many pencils does Aiden have now?",
        "subtraction_task7": "Olivia has {{initialAmount}} balloons. {{subtractAmount}} balloons pop during the party. How many balloons does Olivia have left?",
        "subtraction_task8": "Lucas has {{initialAmount}} chocolate bars. He shares {{subtractAmount}} chocolate bars with his friends. How many chocolate bars does Lucas have now?",
        "subtraction_task9": "Emma has {{initialAmount}} books. She returns {{subtractAmount}} books back to the library. How many books does Emma have now?",
        "subtraction_task10": "Ethan has {{initialAmount}} pieces of candy. He gives {{subtractAmount}} pieces to his brother. How many pieces of candy does Ethan have left?",
        "addition_unknown_task1": "Sarah has {{initialAmount}} pencils. After buying some more, she has {{totalAmount}} pencils. How many pencils did she buy?",
        "addition_unknown_task2": "Ben has {{initialAmount}} cookies. After his mom gave him more, he has {{totalAmount}} cookies. How many cookies did his mom give him?",
        "addition_unknown_task3": "Lily has {{initialAmount}} birds. She found some more in her garden, and now she has {{totalAmount}} birds. How many birds did she find?",
        "addition_unknown_task4": "Jack has {{initialAmount}} toy cars. After his birthday, he has {{totalAmount}} toy cars. How many toy cars did he receive for his birthday?",
        "addition_unknown_task5": "Emily has {{initialAmount}} flowers. After adding some from her neighbor, she has {{totalAmount}} flowers. How many flowers did her neighbor give her?",
        "addition_unknown_task6": "Adam has {{initialAmount}} marbles. After winning some more, he has {{totalAmount}} marbles. How many marbles did he win?",
        "addition_unknown_task7": "Chloe has {{initialAmount}} books. After a trip to the library, she has {{totalAmount}} books. How many books did she borrow from the library?",
        "addition_unknown_task8": "Ethan has {{initialAmount}} stickers. After a school event, he has {{totalAmount}} stickers. How many stickers did he get at the event?",
        "addition_unknown_task9": "Grace has {{initialAmount}} crayons. After her friend shared some, she has {{totalAmount}} crayons. How many crayons did her friend give her?",
        "addition_unknown_task10": "Oliver has {{initialAmount}} chocolates. After receiving some as a gift, he has {{totalAmount}} chocolates. How many chocolates did he receive?",
        "subtraction_unknown_task1": "Mike starts with {{initialAmount}} apples. After eating some, he has {{remainingAmount}} apples left. How many apples did he eat?",
        "subtraction_unknown_task2": "Alice starts with {{initialAmount}} stickers. After giving some away, she has {{remainingAmount}} stickers left. How many stickers did she give away?",
        "subtraction_unknown_task3": "James starts with {{initialAmount}} candies. After sharing some with his brother, he has {{remainingAmount}} candies left. How many candies did he share?",
        "subtraction_unknown_task4": "Lily starts with {{initialAmount}} books. After returning some to the library, she has {{remainingAmount}} books left. How many books did she return?",
        "subtraction_unknown_task5": "Alex starts with {{initialAmount}} toy cars. After losing some, he has {{remainingAmount}} toy cars left. How many toy cars did he lose?",
        "subtraction_unknown_task6": "Emma starts with {{initialAmount}} flowers. After some wilted, she has {{remainingAmount}} flowers left. How many flowers wilted?",
        "subtraction_unknown_task7": "Noah starts with {{initialAmount}} balloons. After some popped, he has {{remainingAmount}} balloons left. How many balloons popped?",
        "subtraction_unknown_task8": "Sophie starts with {{initialAmount}} crayons. After giving some to her friend, she has {{remainingAmount}} crayons left. How many crayons did she give?",
        "subtraction_unknown_task9": "Ethan starts with {{initialAmount}} chocolates. After eating some, he has {{remainingAmount}} chocolates left. How many chocolates did he eat?",
        "subtraction_unknown_task10": "Grace starts with {{initialAmount}} cookies. After sharing some with her friends, she has {{remainingAmount}} cookies left. How many cookies did she share?"
      }
    },
    "addition_numbers_max10": "Addition of Single-Digit Numbers",
    "addition_numbers_max20": "Addition up to 20",
    "addition_numbers_max100": "Addition up to 100",
    "subtraction_numbers_max10": "Subtraction of Single-Digit Numbers",
    "subtraction_numbers_max20": "Subtraction up to 20",
    "subtraction_numbers_max100": "Subtraction up to 100",
    "multiplication_numbers_max100": "Multiplication up to 100",
    "division_numbers_max100": "Division up to 100"
  }
}
