import {createAuthApiClient} from "./api/authApiClient";

export function getApiBaseUrl(): string {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    // if (['localhost', '127.0.0.1', '::1'].includes(hostname) || hostname.startsWith('10.0.0.')) {
    //     return `http://${hostname}:3000`;
    // } else {
    return `${protocol}//api.${hostname}`;
    //}
}

export const authApiClient = createAuthApiClient(getApiBaseUrl());


