{
  "button_continue": "Weiter",
  "school_year": "Klasse",
  "countries": {
    "germany": "Deutschland",
    "united_kingdom": "Vereinigtes Königreich",
    "united_states": "Vereinigte Staaten"
  },
  "settings": {
    "title": "Einstellungen",
    "username": "Benutzername",
    "general": "Allgemeines",
    "receive_notifications": "Erhalte Benachrichtigungen",
    "choose_school_year": "Schulklasse",
    "choose_school_country": "Schulland",
    "maths": {
      "title": "Mathematik",
      "prefer_cross": "Bevorzuge Kreuz × für Multiplikation",
      "prefer_obelus": "Bevorzuge Obelus ÷ für Division"
    },
    "ui_language": "Spracheinstellung",
    "activities": "Aktivitäten",
    "logout": "Abmelden",
    "delete_account": "Konto löschen"
  },
  "ui_language": {
    "title": "Spracheinstellung",
    "british_english": "Britisches Englisch",
    "american_english": "Amerikanisches Englisch",
    "german": "Deutsch"
  },
  "maths": {
    "year1": {
      "text_tasks": {
        "addition_task1": "Kevin hat {{initialAmount}} Äpfel. Seine Freundin Nina gibt ihm {{addAmount}} weitere Äpfel. Wie viele Äpfel hat Kevin jetzt?",
        "addition_task2": "Maya findet {{initialAmount}} Muscheln am Strand. Sie findet später {{addAmount}} weitere Muscheln. Wie viele Muscheln hat Maya insgesamt?",
        "addition_task3": "Alex hat {{initialAmount}} Aufkleber. Er bekommt von seiner Lehrerin {{addAmount}} weitere Aufkleber als Belohnung. Wie viele Aufkleber hat Alex jetzt?",
        "addition_task4": "Sophie hat {{initialAmount}} Perlen. Sie kauft noch {{addAmount}} Perlen dazu, um eine Kette zu machen. Wie viele Perlen hat Sophie jetzt?",
        "addition_task5": "Max hat {{initialAmount}} Spielzeugautos. Sein Cousin schenkt ihm zu seinem Geburtstag noch {{addAmount}} Autos dazu. Wie viele Spielzeugautos hat Max jetzt?",
        "addition_task6": "Lukas hat {{initialAmount}} Bleistifte. Er findet in seiner Schreibtischschublade noch {{addAmount}} Bleistifte. Wie viele Bleistifte hat Lukas insgesamt?",
        "addition_task7": "Anna hat {{initialAmount}} Luftballons. Auf der Party bekommt sie noch {{addAmount}} Ballons dazu. Wie viele Luftballons hat Anna jetzt?",
        "addition_task8": "Felix hat {{initialAmount}} Schokoriegel. Sein Bruder gibt ihm noch {{addAmount}} Schokoriegel dazu. Wie viele Schokoriegel hat Felix jetzt?",
        "addition_task9": "Laura hat {{initialAmount}} Bücher. Sie leiht sich aus der Bibliothek noch {{addAmount}} Bücher aus. Wie viele Bücher hat Laura insgesamt?",
        "addition_task10": "Tim hat {{initialAmount}} Bonbons. Von seinem Freund bekommt er noch {{addAmount}} Bonbons geschenkt. Wie viele Bonbons hat Tim jetzt?",

        "subtraction_task1": "Kevin hat {{initialAmount}} Kekse. Er isst nach dem Mittagessen {{subtractAmount}} Kekse. Wie viele Kekse hat Kevin noch?",
        "subtraction_task2": "Maya hat {{initialAmount}} Buntstifte. Sie gibt ihrer Freundin {{subtractAmount}} Stifte. Wie viele Buntstifte hat Maya jetzt?",
        "subtraction_task3": "Alex hat {{initialAmount}} Murmeln. Beim Spielen verliert er {{subtractAmount}} Murmeln. Wie viele Murmeln hat Alex übrig?",
        "subtraction_task4": "Sophie hat {{initialAmount}} Haarspangen. Sie gibt ihrer Schwester {{subtractAmount}} Spangen. Wie viele Haarspangen hat Sophie jetzt?",
        "subtraction_task5": "Max hat {{initialAmount}} Aufkleber. Er benutzt {{subtractAmount}} Stück, um sein Heft zu dekorieren. Wie viele Aufkleber hat Max übrig?",
        "subtraction_task6": "Lukas hat {{initialAmount}} Bleistifte. Er gibt seinen Klassenkameraden {{subtractAmount}} Stifte. Wie viele Bleistifte hat Lukas noch?",
        "subtraction_task7": "Anna hat {{initialAmount}} Luftballons. Während der Party platzen {{subtractAmount}} Ballons. Wie viele Luftballons hat Anna jetzt?",
        "subtraction_task8": "Felix hat {{initialAmount}} Schokoriegel. Er teilt {{subtractAmount}} Riegel mit seinen Freunden. Wie viele Schokoriegel hat Felix noch?",
        "subtraction_task9": "Laura hat {{initialAmount}} Bücher. Sie gibt {{subtractAmount}} Bücher zurück in die Bibliothek. Wie viele Bücher hat Laura noch?",
        "subtraction_task10": "Tim hat {{initialAmount}} Bonbons. Er gibt seinem Bruder {{subtractAmount}} Stück. Wie viele Bonbons hat Tim noch?",

        "addition_unknown_task1": "Sarah hat {{initialAmount}} Bleistifte. Nachdem sie noch einige gekauft hat, hat sie {{totalAmount}} Bleistifte. Wie viele Bleistifte hat sie gekauft?",
        "addition_unknown_task2": "Ben hat {{initialAmount}} Kekse. Nachdem seine Mutter ihm noch einige gegeben hat, hat er {{totalAmount}} Kekse. Wie viele Kekse hat ihm seine Mutter gegeben?",
        "addition_unknown_task3": "Lily hat {{initialAmount}} Vögel. Sie hat noch einige in ihrem Garten gefunden und hat jetzt {{totalAmount}} Vögel. Wie viele Vögel hat sie gefunden?",
        "addition_unknown_task4": "Jakob hat {{initialAmount}} Spielzeugautos. Nach seinem Geburtstag hat er {{totalAmount}} Autos. Wie viele Autos hat er zum Geburtstag bekommen?",
        "addition_unknown_task5": "Emilia hat {{initialAmount}} Blumen. Nachdem sie noch einige von ihrer Nachbarin hinzugefügt hat, hat sie {{totalAmount}} Blumen. Wie viele Blumen hat ihre Nachbarin ihr gegeben?",
        "addition_unknown_task6": "Max hat {{initialAmount}} Murmeln. Nachdem er noch einige gewonnen hat, hat er {{totalAmount}} Murmeln. Wie viele Murmeln hat er gewonnen?",
        "addition_unknown_task7": "Sophie hat {{initialAmount}} Bücher. Nach einem Besuch in der Bibliothek hat sie {{totalAmount}} Bücher. Wie viele Bücher hat sie aus der Bibliothek ausgeliehen?",
        "addition_unknown_task8": "Finn hat {{initialAmount}} Aufkleber. Nach einer Schulveranstaltung hat er {{totalAmount}} Aufkleber. Wie viele Aufkleber hat er bei der Veranstaltung bekommen?",
        "addition_unknown_task9": "Mia hat {{initialAmount}} Buntstifte. Nachdem ihre Freundin ihr einige gegeben hat, hat sie {{totalAmount}} Stifte. Wie viele Stifte hat ihre Freundin ihr gegeben?",
        "addition_unknown_task10": "Leon hat {{initialAmount}} Schokoladen. Nachdem er einige als Geschenk erhalten hat, hat er {{totalAmount}} Schokoladen. Wie viele Schokoladen hat er bekommen?",

        "subtraction_unknown_task1": "Mike beginnt mit {{initialAmount}} Äpfeln. Nachdem er einige gegessen hat, hat er noch {{remainingAmount}} Äpfel übrig. Wie viele Äpfel hat er gegessen?",
        "subtraction_unknown_task2": "Alice beginnt mit {{initialAmount}} Aufklebern. Nachdem sie einige weggegeben hat, hat sie noch {{remainingAmount}} Aufkleber übrig. Wie viele Aufkleber hat sie weggegeben?",
        "subtraction_unknown_task3": "James beginnt mit {{initialAmount}} Bonbons. Nachdem er einige mit seinem Bruder geteilt hat, hat er noch {{remainingAmount}} Bonbons übrig. Wie viele Bonbons hat er geteilt?",
        "subtraction_unknown_task4": "Lily beginnt mit {{initialAmount}} Büchern. Nachdem sie einige in die Bibliothek zurückgegeben hat, hat sie noch {{remainingAmount}} Bücher. Wie viele Bücher hat sie zurückgegeben?",
        "subtraction_unknown_task5": "Max beginnt mit {{initialAmount}} Spielzeugautos. Nachdem er einige verloren hat, hat er noch {{remainingAmount}} Autos. Wie viele Autos hat er verloren?",
        "subtraction_unknown_task6": "Sophie beginnt mit {{initialAmount}} Blumen. Nachdem einige verwelkt sind, hat sie noch {{remainingAmount}} Blumen. Wie viele Blumen sind verwelkt?",
        "subtraction_unknown_task7": "Finn beginnt mit {{initialAmount}} Luftballons. Nachdem einige geplatzt sind, hat er noch {{remainingAmount}} Ballons. Wie viele Ballons sind geplatzt?",
        "subtraction_unknown_task8": "Mia beginnt mit {{initialAmount}} Buntstiften. Nachdem sie einige ihrer Freundin gegeben hat, hat sie noch {{remainingAmount}} Stifte. Wie viele Stifte hat sie gegeben?",
        "subtraction_unknown_task9": "Leon beginnt mit {{initialAmount}} Schokoladen. Nachdem er einige gegessen hat, hat er noch {{remainingAmount}} Schokoladen. Wie viele Schokoladen hat er gegessen?",
        "subtraction_unknown_task10": "Emma beginnt mit {{initialAmount}} Keksen. Nachdem sie einige mit ihren Freunden geteilt hat, hat sie noch {{remainingAmount}} Kekse. Wie viele Kekse hat sie geteilt?"

      }
    },
    "title": "Mathematik",
    "addition_numbers_max10": "Addition von Einerzahlen",
    "addition_numbers_max20": "Addition bis 20",
    "addition_numbers_max100": "Addition bis 100",
    "subtraction_numbers_max10": "Subtraktion von Einerzahlen",
    "subtraction_numbers_max20": "Subtraktion bis 20",
    "subtraction_numbers_max100": "Subtraktion bis 100",
    "multiplication_numbers_max100": "Multiplikation bis 100",
    "division_numbers_max100": "Division bis 100"
  }
}
