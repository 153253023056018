import {h} from "preact";
import {useContext, useEffect, useRef, useState} from "preact/hooks";
import {ApiClient} from "../../api/realApiClient";
import {styled} from "goober";
import SimpleKeyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import {EnglishKeyboard} from "./englishKeyboard";
import {GlobalContext} from "../../GlobalContext";
import {deobfuscateSolution} from "../../lib/simpleScrambler";
import {playAudio} from "../../lib/globalAssets";
import {route} from 'preact-router';
import {Word} from "vok-shared/dto/outgoing/spelling";
import StatusBar from "./StatusBar";
import AudioPlayer from "./AudioPlayer";
import SpellingInput from "./SpellingInput";
import FinishScreen, {FinishStatsProps} from "./FinishScreen";

const client = ApiClient;

const CenterContainerMobile = styled('div')`
    display: flex;
    align-items: center;
    height: 100%;
`;

const CenterFullHeightContainerDesktop = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const WithKeyboardContainer = styled('div')`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const QuestionScreen = () => {

    const queryParams = new URLSearchParams(document.location.search);

    // Get specific query parameter
    const language = queryParams.get('language');
    const wordType = queryParams.get('wordType');
    if (language === null || wordType === null) {
        // navigate to the welcome screen
        console.error('no language or wordType');
        route('/');
        return (<div/>);
    }

    const keyboardRef = useRef<SimpleKeyboard | null>(null);
    const stats = useRef<FinishStatsProps & {wrongWords: Record<string, number>}>({wordsAsked: 0, correct: 0, incorrect: 0, hardestWord: '', difficultWords: [], wrongWords: {}});
    const [sessionUuid, setSessionUuid] = useState<string>('');
    const [screenState, setScreenState] = useState<'init' | 'nothing-to-do' | 'questions' | 'finish'>('init');
    const [words, setWords] = useState<Word[]>([]);
    const [currentWord, setCurrentWord] = useState<number | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [feedback, setFeedback] = useState<'waiting' | 'correct' | 'retry'>('waiting');
    const [currentWordWasAtLeastOnceIncorrect, setCurrentWordWasAtLeastOnceIncorrect] = useState<boolean>(false);
    const [showSolution, setShowSolution] = useState<string | null>(null);

    const [feedbackSound, setFeedbackSound] = useState<boolean | null>(null);

    const {isDebug, isMobileDevice, user, timeZone} = useContext(GlobalContext);

    useEffect(() => {
        if (feedbackSound === true) {
            playAudio('success');
        } else if (feedbackSound === false) {
            playAudio('failure');
        }
    }, [feedbackSound]);

    const loadWords = async () => {
        const result = await client.getWords({language, wordType, level: 5, timeZone});
        if (result.words.length === 0) {
            setScreenState('nothing-to-do');
            return;
        }
        setScreenState('questions');
        setSessionUuid(result.sessionUuid);
        setWords(result.words.map((word: Word) => {
            return {...word, solution: deobfuscateSolution(word.solution, user?.subject ?? '')}
        }));
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(sw => {
                sw.active!.postMessage({
                    action: 'cache',
                    files: [result.words.map((word: Word) => word.audioUrl)]
                });
            });
        }
        setCurrentWord(0);
        stats.current.wordsAsked = 1;
    };

    const loadNextWord = (currentWords: Word[]) => {
        if (currentWord !== null) {
            const nextWord = currentWord + 1;
            if (nextWord < currentWords.length) {
                stats.current.wordsAsked++;
                setCurrentWord(nextWord);
            } else {
                // this doesn't give progress bar time to go to 100%
                setScreenState('finish');
            }
        }
    };

    const checkSpelling = () => {
        if (feedback == 'correct') {
            return;
        }
        const wordy = words[currentWord!];
        const isWordCorrect = (inputValue.trim() === wordy.solution);
        if (!isWordCorrect) {
            setCurrentWordWasAtLeastOnceIncorrect(true);
        }
        if (feedback === 'retry') {
            // do not over report answer when the user is already retrying
            if (isWordCorrect) {
                setFeedback('correct');
                setFeedbackSound(true);
            } else {
                setFeedback('retry');
                setFeedbackSound(false);
                setShowSolution(wordy.solution);
                keyboardRef.current?.clearInput();
                setInputValue('');
            }
        } else {
            if (isWordCorrect) {
                stats.current.correct++;
                setFeedback('correct');
                setFeedbackSound(true);
                client.reportAnswer({wordId: wordy.id, sessionUuid});
            } else {
                stats.current.incorrect++;
                stats.current.wrongWords[wordy.solution] = (stats.current.wrongWords[wordy.solution] || 0) + 1;
                setFeedback('retry');
                setFeedbackSound(false);
                setShowSolution(wordy.solution);
                keyboardRef.current?.clearInput();
                setInputValue('');
                client.reportAnswer({wordId: wordy.id, sessionUuid, answer: inputValue});
            }
        }
    };


    const onChange = (input: string) => {
        if (input === '\n') {
            return;
        }
        if (window.navigator.vibrate) {
            window.navigator.vibrate(1);
        }
        setInputValue(input);
    };

    useEffect(() => {
        if (!user) {
            console.log('no user');
            // route('/');
            // return;
        }
        console.log('question screen mounted');
        loadWords();
    }, []);

    useEffect(() => {
        if (screenState === 'questions') {
            if (isMobileDevice) {
                keyboardRef.current = new EnglishKeyboard(language, onChange).keyboard;
            }
        }
        return () => {
            if (keyboardRef.current) {
                keyboardRef.current?.destroy();
            }
        }
    }, [screenState]);

    useEffect(() => {
        let timeoutId: number | NodeJS.Timeout;
        if (feedback === 'correct') {
            timeoutId = setTimeout(() => {
                let currentWords = words;
                if (currentWordWasAtLeastOnceIncorrect) {
                    // add current word at the end of the list
                    const wordy = words[currentWord!];
                    currentWords = [...words, wordy];
                    setWords(currentWords);
                }
                loadNextWord(currentWords);
                keyboardRef.current?.clearInput();
                setCurrentWordWasAtLeastOnceIncorrect(false);
                setInputValue('');
                setShowSolution(null);
                setFeedbackSound(null);
                setFeedback('waiting');
            }, 250);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [feedback]);
    // this height calc is insane
    // 100vh can change when the url bar appears or disappears - hence the JS in index.ts and using the var here

    const Debug = () => {
        return (
            <div>
                <div>Debug: {JSON.stringify(stats.current)}</div>
                <div>ScreenState: {screenState}</div>
                <div>CurrentWord: {currentWord}</div>
                <div>Feedback: {feedback}</div>
                <div>InputValue: {inputValue}</div>
                <div>SessionUuid: {sessionUuid}</div>
                <button onClick={() => {setInputValue(words[currentWord!].solution);;}}>C</button>
                <button onClick={() => {setInputValue('qasd'); setTimeout(() => checkSpelling(),1);}}>I</button>
            </div>
        );
    }

    const QuestionScreenDesktop = (
        <CenterFullHeightContainerDesktop>
            {isDebug && <Debug/>}
            <div style={{width: '50vw'}}>
                {currentWord !== null && <StatusBar currentWord={currentWord} amountWords={words.length} width={33}/>}
                {currentWord !== null && <AudioPlayer solution={showSolution} word={words[currentWord]}/>}
                <SpellingInput value={inputValue} onChange={setInputValue} onSubmit={checkSpelling}
                               mobileDevice={isMobileDevice}/>
            </div>
        </CenterFullHeightContainerDesktop>
    );

    const QuestionScreenMobile = (
        <WithKeyboardContainer>
            {isDebug && <Debug/>}
            <div style={{width: '90%', flexGrow: 1}}>
                <CenterContainerMobile>
                    <div style={{width: '100%'}}>
                        {currentWord !== null &&
                            <StatusBar currentWord={currentWord} amountWords={words.length} width={100}/>}
                        {currentWord !== null && <AudioPlayer solution={showSolution} word={words[currentWord]}/>}
                        <SpellingInput value={inputValue} onChange={setInputValue} onSubmit={checkSpelling}
                                       mobileDevice={isMobileDevice}/>
                    </div>
                </CenterContainerMobile>
            </div>

            <div style={{}} className={"simple-keyboard"} id="simple-keyboard"/>

        </WithKeyboardContainer>
    );

    if (screenState === 'nothing-to-do') {
        return (<h1>You are done with Spelling for today!</h1>);
    }
    if (screenState === 'questions') {
        return isMobileDevice ? QuestionScreenMobile : QuestionScreenDesktop;
    }
    if (screenState === 'finish') {
        const wordList = Object.keys(stats.current.wrongWords).map(key => [key, stats.current.wrongWords[key]]) as [string, number][];
        wordList.sort((a, b) => b[1] - a[1]);
        stats.current.difficultWords = wordList.slice(0, 3).map(([word, count]) => ({word, count}));
        stats.current.hardestWord = wordList[0]?.[0] ?? null;
        return (<FinishScreen {...stats.current}/>);
    }
    return (<div/>);
}

export default QuestionScreen;
