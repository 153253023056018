import {Fragment, h} from 'preact';
import {GroupButton, MenuSecondaryButton} from "./ui/Button";
import {useTranslation} from "react-i18next";
import {ResponsiveContainer, TopFlowContainer} from "./ui/Container";
import {Title} from "./ui/Text";
import VSpace from "./ui/VSpace";
import {useState} from "preact/hooks";

const SchoolCountryScreen = ({currentCountry, onContinue}: {currentCountry: string, onContinue: (country: string) => void}) => {
    const {t} = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState<string>(currentCountry);
    const mapCountryCodeToI18N: Record<string, string> = {
        "de-DE": t('countries.germany'),
        "en-GB": t('countries.united_kingdom'),
        "en-US": t('countries.united_states')
    }
    return (
        <ResponsiveContainer>
            <TopFlowContainer centerVContainerMaxHeight={"700px"}>
                <Title>{t('settings.choose_school_country')}</Title>
                {[t('de-DE'), t('en-GB'), t('en-US')].map((i) => {
                    return (
                        <Fragment key={i}>
                            <GroupButton selected={i === selectedCountry} onClick={() => setSelectedCountry(i)}>{`${mapCountryCodeToI18N[i]}`}</GroupButton>
                            <VSpace/>
                        </Fragment>
                    )
                })}
                <VSpace space="2"/>
                <MenuSecondaryButton onclick={() => onContinue(selectedCountry)}>{t('button_continue')}</MenuSecondaryButton>
            </TopFlowContainer>
        </ResponsiveContainer>
    );
};

export default SchoolCountryScreen;
