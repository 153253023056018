import {useEffect, useRef, useState} from 'preact/hooks';
import {h} from "preact";

import {styled} from 'goober';
import {Word} from "vok-shared/dto/outgoing/spelling";

const CenteredContainer = styled('div')`
  display: flex;
  justify-content: start;
  width: 100%;
    // animation: ${props => props.isAnimating ? 'moveLeft 2s forwards' : 'none'};
  // @keyframes moveLeft {
  //   to { justify-content: flex-start; }
  // }
`;

const Headphones = styled('i')`
  font-size: 4rem;
`;

const AudioButton = styled('button')`
  display: flex;
  align-items: center;
  background: none;
  color: black;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 1.5rem;
  cursor: pointer;


  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 8px;
  }
`;

const AudioText = styled('span')`
  opacity: 0;
  animation: fadeIn 2s forwards;
  animation-delay: 1.5s;
  margin-left: 1rem;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

export interface AudioPlayerProps {
    word: Word;
    solution: string | null;
}

const apiBaseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

const AudioPlayer = (props: AudioPlayerProps) => {
    const [isAnimating, setIsAnimating] = useState(false);

    const audioUrl = `${apiBaseUrl}${props.word.audioUrl}`;
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = audioUrl;
            audioRef.current?.play();
        }
    }, [props.word]);


    return (
        <CenteredContainer isAnimating={isAnimating}>
            <audio ref={audioRef} onEnded={() => setIsAnimating(false)} />
            <AudioButton isAnimating={isAnimating} onClick={() => {
                if (audioRef.current) {
                    audioRef.current?.play();
                }
                setIsAnimating(true);
            }}>
                <Headphones className="fa-solid fa-headphones-simple"></Headphones>
                <AudioText>{props.solution}</AudioText>
            </AudioButton>
        </CenteredContainer>
    );
};

export default AudioPlayer;
