import {h} from 'preact';
import {styled} from "goober";

const Frame = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const NotFoundPage = () => {
    return (
        <Frame>
            <div>We are sorry :(</div>
            <div>But this page does not exist</div>
        </Frame>
    );
};

export default NotFoundPage;
