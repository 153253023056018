import {h} from "preact";
import {styled} from "goober";
import {route} from 'preact-router';
import {Button, ContinueButton, GroupButton, SecondaryButton} from "../ui/Button";
import {useState} from "preact/hooks";
import {SubTitle, Title} from "../ui/Text";
import {CenterVContainer, ResponsiveContainer} from "../ui/Container";

const OptionButton = styled(GroupButton)`
    width: calc(50% - 0.5rem);
    font-size: 1rem;
    @media ${props => props.theme.breakpoints.small} {
        width: 100%;
    }
`;

const DifficultyButton = styled(GroupButton)`
    width: calc(20% - 1.5rem);
    aspect-ratio: 1;
`;

const ButtonGridBase = styled('div')`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0;
    width: 100%;

`;

const ButtonGrid = styled(ButtonGridBase)`
    @media ${props => props.theme.breakpoints.small} {
        flex-direction: column;
        align-items: stretch;
    }

    @media ${props => props.theme.breakpoints.medium}, ${props => props.theme.breakpoints.large} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const DifficultyButtonGrid = styled(ButtonGridBase)`
    @media ${props => props.theme.breakpoints.small}, ${props => props.theme.breakpoints.medium}, ${props => props.theme.breakpoints.large} {
        flex-direction: row;
        justify-content: space-between;
    }
`;


const Icon = styled('i')`
    margin-right: 0.5rem;
`;

const FlagIcon = () => <Icon className="fa-solid fa-flag fa-xl"/>;

const mapLanguage = {
    'German': 'de-DE',
    'English': 'en-AU'
};

const mapWordType = {
    'Single': 'single',
    'Sentences': 'sentences'
};

const LanguageSelectionScreen = () => {

    const [language, setLanguage] = useState<'German' | 'English'>("English");
    const [mode, setMode] = useState<'Single' | 'Sentences'>("Single");
    const [difficulty, setDifficulty] = useState<number>(5);

    const onContinue = () => {
        route(`/spelling?language=${mapLanguage[language]}&wordType=${mapWordType[mode]}&difficulty=${difficulty}`);
    };

    return (
        <ResponsiveContainer>
            <CenterVContainer centerVContainerMaxHeight={"700px"}>
            <Title>Language Practice</Title>
            <SubTitle>Language</SubTitle>
            <ButtonGrid>
                <OptionButton selected={language === 'English'} onclick={() => setLanguage('English')}>
                    <FlagIcon/> English
                </OptionButton>
                <OptionButton selected={language === 'German'} onclick={() => setLanguage('German')}>
                    <FlagIcon/> German
                </OptionButton>
            </ButtonGrid>
            <SubTitle>Mode</SubTitle>
            <ButtonGrid>
                <OptionButton selected={mode === 'Single'} onclick={() => setMode('Single')}>
                    Single Words
                </OptionButton>
                <OptionButton selected={mode === 'Sentences'} onclick={() => setMode('Sentences')}>
                    Sentences
                </OptionButton>
            </ButtonGrid>
            <SubTitle>Difficulty</SubTitle>
            <DifficultyButtonGrid>
                {[1, 2, 3, 4, 5].map((i) => (
                    <DifficultyButton selected={difficulty === i} onclick={() => setDifficulty(i)}>
                        <span>{i}</span>
                    </DifficultyButton>
                ))}
            </DifficultyButtonGrid>
            <ContinueButton onclick={() => onContinue()}>Continue</ContinueButton>
            </CenterVContainer>
        </ResponsiveContainer>
    );
};

export default LanguageSelectionScreen;
