export const fontSizes = {
    small: '0.8rem',
    normal: '1rem',
    large: '1.2rem',
    h1: '2rem',
    h2: '1.75rem',
    h3: '1.5rem',
};

export const baseSpacing = 8; // 8px
