import {h} from "preact";
import {styled} from "goober";
import {GroupButton, MenuSecondaryButton} from "./ui/Button";
import {useContext, useEffect, useState} from "preact/hooks";
import {Title} from "./ui/Text";
import {ResponsiveContainer, TopFlowContainer} from "./ui/Container";
import {GlobalContext} from "../GlobalContext";
import i18n from "i18next";
import VSpace from "./ui/VSpace";
import {useTranslation} from "react-i18next";

const OptionButton = styled(GroupButton)`
    font-size: 1rem;
    width: 100%;
`;

const ButtonGridBase = styled('div')`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0;
    width: 100%;
`;

const ButtonGrid = styled(ButtonGridBase)`
        flex-direction: column;
        align-items: stretch;
`;

const Icon = styled('i')`
    margin-right: 0.5rem;
`;

const FlagIcon = () => <Icon className="fa-solid fa-flag fa-xl"/>;

const UILanguageScreen = ({onContinue}: {onContinue: () => void}) => {
    const {t} = useTranslation();

    const [language, setLanguage] = useState<'de-DE' | 'en-UK' | 'en-US' | 'fr-FR'>(i18n.language as any);

    useEffect(() => {
        if (language !== i18n.language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    return (
        <ResponsiveContainer>
            <TopFlowContainer centerVContainerMaxHeight={"700px"}>
                <Title>{t('ui_language.title')}</Title>
                <ButtonGrid>
                    <OptionButton selected={language === 'en-UK'} onclick={() => setLanguage('en-UK')}>
                        <FlagIcon/> {t('ui_language.british_english')}
                    </OptionButton>
                    <OptionButton selected={language === 'en-US'} onclick={() => setLanguage('en-US')}>
                        <FlagIcon/> {t('ui_language.american_english')}
                    </OptionButton>
                    <OptionButton selected={language === 'de-DE'} onclick={() => setLanguage('de-DE')}>
                        <FlagIcon/> {t('ui_language.german')}
                    </OptionButton>
                </ButtonGrid>
                <VSpace space="2"/>
                <MenuSecondaryButton onclick={() => onContinue()}>{t('button_continue')}</MenuSecondaryButton>
            </TopFlowContainer>
        </ResponsiveContainer>
    );
};

export default UILanguageScreen;
