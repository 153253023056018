import Keyboard from "simple-keyboard";

export class EnglishKeyboard {

    public keyboard: Keyboard;

    getLayout(language: string) {
        if (language === "en-US" || language === "en-GB" || language === "en-AU" || language === "en-CA" || language === "en-NZ" || language === "en-ZA") {
            return {
                layout: {
                    default: [
                        "q w e r t y u i o p",
                        "a s d f g h j k l",
                        "{shift} z x c v b n m {backspace}",
                        "{numbers} {space} . {enter}"
                    ],
                    shift: [
                        "Q W E R T Y U I O P",
                        "A S D F G H J K L",
                        "{shift} Z X C V B N M {backspace}",
                        "{numbers} {space} . {enter}"
                    ],
                    numbers: ["1 2 3 4 5 6 7 8 9 0",
                        "$ ! ~ & = # [ ]",
                        ". _ - + {backspace}",
                        "{abc} {space} . {enter}"]
                },
                display: {
                    "{numbers}": "123",
                    "{enter}": "return",
                    "{escape}": "esc ⎋",
                    "{tab}": "tab ⇥",
                    "{backspace}": "⌫",
                    "{capslock}": "caps lock ⇪",
                    "{shift}": "⇧",
                    "{controlleft}": "ctrl ⌃",
                    "{controlright}": "ctrl ⌃",
                    "{altleft}": "alt ⌥",
                    "{altright}": "alt ⌥",
                    "{metaleft}": "cmd ⌘",
                    "{metaright}": "cmd ⌘",
                    "{abc}": "ABC",
                    "{space}": "space"
                },
            };
        }
        if (language === "de-DE" || language === "de-AT" || language === "de-CH" || language === "de-LI" || language === "de-LU") {
            return {
                layout: {
                    default: [
                        "q w e r t z u i o p \u00FC \u00DF",
                        "a s d f g h j k l \u00F6 \u00E4",
                        "{shift} y x c v b n m {backspace}",
                        "{numbers} {space} . {enter}"
                    ],
                    shift: [
                        "Q W E R T Z U I O P \u00DC \u00DF",
                        "A S D F G H J K L \u00D6 \u00C4",
                        "{shift} Y X C V B N M {backspace}",
                        "{numbers} {space} . {enter}",
                    ],
                    numbers: ["1 2 3 4 5 6 7 8 9 0",
                        "$ ! ~ & = # [ ]",
                        ". _ - + {backspace}",
                        "{abc} {space} . {enter}"]
                },
                display: {
                    "{numbers}": "123",
                    "{enter}": "return",
                    "{escape}": "esc ⎋",
                    "{tab}": "tab ⇥",
                    "{backspace}": "⌫",
                    "{capslock}": "caps lock ⇪",
                    "{shift}": "⇧",
                    "{controlleft}": "ctrl ⌃",
                    "{controlright}": "ctrl ⌃",
                    "{altleft}": "alt ⌥",
                    "{altright}": "alt ⌥",
                    "{metaleft}": "cmd ⌘",
                    "{metaright}": "cmd ⌘",
                    "{abc}": "ABC"
                },
            }
        }
    }

    public onKeyDown?: (event: string) => void;

    constructor(language: string, private onInput: (input: string) => void, onKeyDown?: (event: string) => void) {
        const layout = this.getLayout(language)! as any;
        this.onKeyDown = onKeyDown;
        this.keyboard = new Keyboard(".simple-keyboard", {
            theme: "hg-theme-default hg-layout-default my-custom-keyboard",
            mergeDisplay: true,
            layoutName: "default",
            physicalKeyboardHighlight: true,
            physicalKeyboardHighlightPressUseClick: true,
            physicalKeyboardHighlightPreventDefault: true,
            ...layout,
            onChange: input => onInput(input),
            onKeyPress: button => this.onKeyPress(button),});
    }

    dispose() {
        this.keyboard.destroy();
    }

    onKeyPress(button: string) {
        if (this.onKeyDown) {
            this.onKeyDown(button);
        }
        if (button === "{enter}") {
            this.onInput("\n");
            return;
        }
        if (button === "{shift}" || button === "{lock}") {
            this.handleShift();
            return;
        }
        if (button === "{numbers}" || button === "{abc}") {
            this.handleNumbers();
            return;
        }
        // fall back to default with any key pressed
        if (this.keyboard.options.layoutName === "shift") {
            this.keyboard.setOptions({
                layoutName: "default"
            });
        }
    }

    handleShift() {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle
        });
    }

    handleNumbers() {
        let currentLayout = this.keyboard.options.layoutName;
        let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";

        this.keyboard.setOptions({
            layoutName: numbersToggle
        });
    }
}
