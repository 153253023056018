import {authApiClient, getApiBaseUrl} from "../globalDependencies";
import {route} from "preact-router";
import {SpellingAnswer} from "vok-shared/dto/incoming/spellingAnswer";
import {WordSpellingSession} from "vok-shared/dto/outgoing/spelling";
import {CreateDictateSessionRequest} from "vok-shared/dto/incoming/createDictateSessionRequest";
import {UserActivity} from "vok-shared/dto/outgoing/activity";

const apiBaseUrl = getApiBaseUrl();

const handleResponse = (response: Response) => {
    if (!response.ok) {
        if (response.status === 401) {
            console.log('Unauthorized, redirecting to login');
            console.log('response', response);
            route('/login');
        } else {
            throw new Error('Network response was not ok');
        }
    }
}

export const ApiClient = {
    createOrUpdateWebPushNotification: async (subscription: PushSubscription) => {
        const response = await fetch(`${apiBaseUrl}/pushNotifications/web`, {
            method: 'POST',
            ...await authApiClient.getHeaderWithContentType(),
            body: JSON.stringify(subscription),
        });
        handleResponse(response);
    },

    deleteWebPushNotification: async () => {
        const response = await fetch(`${apiBaseUrl}/pushNotifications/web`, {
            method: 'DELETE',
            ...await authApiClient.getHeaderWithContentType(),
        });
        handleResponse(response);
    },

    getWords: async (createDictateSessionRequest: CreateDictateSessionRequest): Promise<WordSpellingSession> => {
        const response = await fetch(`${apiBaseUrl}/language/words`, {
            method: 'POST',
            ...await authApiClient.getHeaderWithContentType(),
            body: JSON.stringify(createDictateSessionRequest),
        });
        handleResponse(response);
        return response.json();
    },

    reportAnswer: async (spellingAnswer: SpellingAnswer) => {
        const response = await fetch(`${apiBaseUrl}/language/report-answer`, {
            method: 'POST',
            ...await authApiClient.getHeaderWithContentType(),
            body: JSON.stringify(spellingAnswer),
        });
        handleResponse(response);
        return response.json();
    },

    getMathsKeywords: async () => {
        const response = await fetch(`${apiBaseUrl}/maths/keywords`, {
            method: 'GET',
            ...await authApiClient.getHeaderWithContentType(),
        });
        handleResponse(response);
        return response.json();
    },

    getMathsQuestions: async (code: string) => {
        const response = await fetch(`${apiBaseUrl}/maths/questions?code=${code}`, {
            method: 'GET',
            ...await authApiClient.getHeaderWithContentType(),
        });
        handleResponse(response);
        return response.json();
    },

    getActivities: async (username: string): Promise<UserActivity[]> => {
        const response = await fetch(`${apiBaseUrl}/activity/${username}`, {
            method: 'GET',
            ...await authApiClient.getHeaderWithContentType(),
        });
        handleResponse(response);
        return response.json();
    }
};
