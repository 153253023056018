import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import i18next from 'i18next';
import de from '../locales/de/de.json';
import enGB from '../locales/en/en-GB.json';

const initI18next = () => {
    i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            resources: {
                'en-GB': {
                    translation: enGB,
                },
                'en-US': {
                    translation: enGB,
                },
                de: {
                    translation: de,
                },
                // add other languages here
            },
            fallbackLng: 'en-GB',
            debug: true,

            interpolation: {
                escapeValue: false, // not needed for react!!
            },

            react: {
             //   defaultTransParent: 'div', // needed for preact
                wait: true,
            },
        } as any);
}
export default initI18next;
