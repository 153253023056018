import {useContext, useEffect, useState} from 'preact/hooks';
import {setup, styled} from 'goober';
import {GlobalStyles} from './styles/globalStyles';
import {createContext, h} from "preact";
import {ApiClient} from "./api/realApiClient";
import {GlobalContext, UserType} from './GlobalContext';
import {MathQuestionsSet} from "./components/maths/MathQuestionsSet";
import Router, {Route, Link, RouterOnChangeArgs} from 'preact-router';
import CreateAccount from "./components/login/CreateAccount";
import NotFoundPage from "./components/NotFoundPage";
import withAuthWall from "./components/common/withAuthWall";
import WelcomeScreen from "./components/WelcomeScreen";
import LoginScreen from "./components/login/LoginScreen";
import SettingsScreen from "./components/SettingsScreen";
import QuestionScreen from "./components/spelling/QuestionScreen";
import LanguageMenuScreen from "./components/language/languageMenuScreen";
import ActivityScreen from "./components/ActivityScreen";
import FinishScreen from "./components/spelling/FinishScreen";
import {loadUserConfiguration} from "./lib/localUserConfiguration";
import MathsMenuScreen from "./components/maths/MathsMenuScreen";
import UILanguageScreen from "./components/UILanguageScreen";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";

const theme = {
    correctionColor: '#ac3235',
    pen: '#002b59',
    lightPen: '#4895e7',
    pencil: '#989c9f',
    darkPencil: '#5f5f5f',
    paper: '#ffffff',
    buttonBackground: '#f0f0f0',
    buttonTextColor: '#ffffff',
    breakpoints: {
        small: '(max-width: 375px)',
        medium: '(min-width: 376px) and (max-width: 1024px)',
        large: '(min-width: 1025px)',
    },
};
const ThemeContext = createContext(theme);
const useTheme = () => useContext(ThemeContext);

setup(h, undefined, useTheme);

const client = ApiClient;

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios

const isMobileDeviceFunc = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || iOS()
};

const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

enum Screen {
    Login,
    Settings,
    Welcome,
    Maths,
    Question,
    Finish
}


const AppContainer = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Content = styled('div')`
    flex: 1;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
`;

/*
Why does it make a difference whether the parent is "relative" for option 2?

ChatGPT
The use of position: relative on a parent container in combination with position: absolute on a child element is crucial for positioning in CSS. Here's why:

Context for Absolute Positioning: When you set an element to position: absolute, its position is determined relative to the nearest positioned ancestor. A positioned element is an element whose position is anything other than static (the default). By setting the parent to position: relative, you establish it as the reference point (or the "positioning context") for its absolutely positioned children.

Containing Block: Without a relatively positioned parent, an absolutely positioned element will position itself in relation to the viewport or the next positioned ancestor up the DOM tree. This can lead to the element appearing in an unexpected location on the page, especially if there are other positioned ancestors that it might relate to.

Control and Predictability: By explicitly setting the parent to position: relative, you gain more control over where the absolutely positioned child will appear. It allows the child's positioning properties (top, right, bottom, left) to be calculated with respect to the parent's box, making the layout more predictable and easier to manage.
 */

function getTimeZone(): string {
    try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone as any;
        if (typeof timeZone === 'string') {
            return timeZone;
        }
    } catch (e) {
        console.error('getTimeZone is not supported', e);
    }
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(offset) / 60;
    return `UTC${offset > 0 ? '-' : '+'}${offsetHours}`;
}


const contextValue = {
    isDebug: window.location.hostname === 'localhost',
    isMobileDevice: isMobileDeviceFunc(),
    hasPhysicalKeyboard: false,
    user: null,
    timeZone: getTimeZone(),
};

const App = () => {
    const screenToBannerTitle = {
        [Screen.Login]: 'EchoPapa',
        [Screen.Settings]: 'Settings',
        [Screen.Welcome]: 'Welcome',
        [Screen.Maths]: 'Maths',
        [Screen.Question]: 'Word Training',
        [Screen.Finish]: 'Finish'
    }

    const timeToGreeting = (): string => {
        const currentHour = new Date().getHours();
        if (currentHour >= 0 && currentHour < 5) {
            return "Good Night";
        } else if (currentHour >= 5 && currentHour < 12) {
            return "Good Morning";
        } else if (currentHour >= 12 && currentHour < 14) {
            return "Good Afternoon";
        } else if (currentHour >= 14 && currentHour < 17) {
            return "Good Afternoon";
        } else if (currentHour >= 17 && currentHour < 23) {
            return "Good Evening";
        } else {
            return "Good Night";
        }
    }

    const [user, _setUser] = useState<UserType | null>(null);

    console.log('App render');

    return (
        <GlobalContext.Provider value={
            {
                ...contextValue,
                userConfiguration: loadUserConfiguration(),
                user,
                setUser: (_) => {
                    console.log('setUser', _);
                    _setUser(_);
                }
            }
        }>
            <GlobalStyles/>
            <Router>
                <Route component={withAuthWall(WelcomeScreen)} path="/"/>
                <Route component={LoginScreen} path="/login"/>
                <Route component={withAuthWall(MathQuestionsSet)} path="/maths/questions"/>
                <Route component={withAuthWall(MathsMenuScreen, true)} path="/maths"/>
                <Route component={withAuthWall(SettingsScreen)} path="/settings"/>
                <Route component={withAuthWall(QuestionScreen)} path="/spelling"/>
                <Route component={withAuthWall(LanguageMenuScreen)} path="/language"/>
                <Route component={CreateAccount} path="/signup"/>
                <Route component={withAuthWall(ActivityScreen)} path="/activity/:username"/>
                <Route component={withAuthWall(FinishScreen)} path="/finish"/>
                <Route default component={NotFoundPage}/>
            </Router>
        </GlobalContext.Provider>
    );
};

export default App;
