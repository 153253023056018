import {h} from "preact";
import {styled} from "goober";
import {useContext, useEffect, useRef, useState} from "preact/hooks";
import {ApiClient} from "../../api/realApiClient";
import {registerLoadingFinished} from "../../lib/externalLibraryLoader";
import {EchoPapaInputField} from "../ui/PaperInputField";
import {authApiClient} from "../../globalDependencies";
import {route} from "preact-router";
import {Button, ContinueButton} from "../ui/Button";
import {clientSideTomCoreSalt, extractHash} from "./CreateAccount";
import {LoginFailReason} from "../../api/authApiClient";
import {GlobalContext} from "../../GlobalContext";
import {CenterVHContainer, ResponsiveContainer} from "../ui/Container";
import {Title} from "../ui/Text";

const client = ApiClient;

const CreateAccount = styled('a')`
    color: ${(props) => props.theme.pen};
    font-size: 0.875rem;
    margin: 0.5rem 0;
    outline: none;
    text-decoration: none;
    font-weight: bold;
`;

const LoginScreen = ({onLoggedIn}: { onLoggedIn: () => void }) => {
    const loginRef = useRef<HTMLDivElement>(null);
    const guestLoginRef = useRef<HTMLButtonElement>(null);
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [nameError, setNameError] = useState<'no-name' | 'wrong-name' | null>(null);
    const [passwordError, setPasswordError] = useState<'no-password' | 'wrong-password' | null>(null);
    const [externalLibrariesLoaded, setExternalLibrariesLoaded] = useState(false);

    const {setUser} = useContext(GlobalContext);

    const loginDomesticUser = async () => {
        if (name === '') {
            setNameError('no-name');
            return;
        }
        if (password === '') {
            setPasswordError('no-password');
            return;
        }
        const hashedPassword = extractHash(dcodeIO.bcrypt.hashSync(password, clientSideTomCoreSalt));
        setUser(null);
        const result = await authApiClient.loginAccount({username: name, password: hashedPassword});
        if (result.failedReason === LoginFailReason.InvalidUsername) {
            setNameError('wrong-name');
        } else if (result.failedReason === LoginFailReason.InvalidPassword) {
            setPasswordError('wrong-password');
        }
        const {accessToken, idToken} = authApiClient.getCurrentAccessToken();
        await setUserFromToken(accessToken, idToken, setUser);
        route('/');
    }

    useEffect(() => {
        if (nameError) {
            setNameError(null);
        }
    }, [name]);

    useEffect(() => {
        if (passwordError) {
            setPasswordError(null);
        }
    }, [password]);

    useEffect(() => {
        authApiClient.getAuthStatus().then(token => {
            if (token) {
                route('/');
                return;
            }
        });
    }, []);

    return (
        <ResponsiveContainer>
            <CenterVHContainer centerVContainerMaxHeight={"700px"}>
            <Title>Welcome back</Title>
                <EchoPapaInputField
                    style={{border: nameError !== null ? '2px solid red' : '1px solid #d6d6d6'}}
                    type="text"
                    onInput={(e: any) => setName(e.currentTarget.value)}
                    value={name}
                    placeholder="username"
                    onEnterPress={loginDomesticUser}/>
                {nameError === 'wrong-name' && <div>
                    <p style={{color: 'red'}}>Sorry! We don't know this username</p>
                </div>}
                <EchoPapaInputField
                    style={{border: passwordError !== null ? '2px solid red' : '1px solid #d6d6d6'}}
                    type="password"
                    onInput={(e: any) => setPassword(e.currentTarget.value)}
                    value={password}
                    placeholder="password"
                    onEnterPress={loginDomesticUser}/>
                {passwordError === 'wrong-password' && <div>
                    <p style={{color: 'red'}}>Sorry! This is the wrong password</p>
                </div>}
                <ContinueButton ref={guestLoginRef} onClick={loginDomesticUser}>Sign in</ContinueButton>
                <CreateAccount href="/signup">New User? Sign Up</CreateAccount>
            </CenterVHContainer>
        </ResponsiveContainer>)
};


export default LoginScreen;
