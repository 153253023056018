import {Fragment, h} from "preact";

import {styled} from 'goober';
import {CenteredParagraph, Paragraph, SubTitle, Title} from "../ui/Text";
import {CenterVContainer, CenterVHContainer, ResponsiveContainer, TopFlowContainer} from "../ui/Container";
import {MenuButton, MenuSecondaryButton} from "../ui/Button";
import {route} from "preact-router";
import VSpace from "../ui/VSpace";

const ImageContainer = styled('div')`
    position: relative;
    width: 100%;
    padding-top: calc(100% * (100 / 300));
`;

const BannerImage = styled('img')`
    position: absolute;
    top: 0;
    left: 45%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 55%;
    transform: scale(1) translateX(-45%);
`;

const StatsGrid = styled('div')`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media ${props => props.theme.breakpoints.medium}, ${props => props.theme.breakpoints.large} {
        grid-template-columns: repeat(2, 1fr);
    }
    gap: 1rem;
    margin-bottom: 2rem;
`;

const StatCard = styled('div')`
    background: #f0f0f0;
    border-radius: 8px;
    padding: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StatTitle = styled('div')`
    font-size: 1.0rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const StatValue = styled('div')`
    font-size: ${props => props.longContent ? '0.75rem' : '1.2rem'};
    font-weight: bold;
`;

const DifficultWords = styled('div')`
    margin-bottom: 2rem;
`;

const WordItem = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`;

export type FinishStatsProps = {
    wordsAsked: number;
    correct: number;
    incorrect: number;
    hardestWord: string | null;
    difficultWords: { word: string, count: number }[];
}

const FinishScreen = (props: FinishStatsProps) => {
    if (!props.wordsAsked) {
        props = {
            wordsAsked: 20,
            correct: 20,
            incorrect: 20,
            hardestWord: null,
            difficultWords: [{word: 'fuxxdieWaldFee', count: 20}, {word: 'fuxxdieWaldFee', count: 10}]
        };
    }
    return (
        <ResponsiveContainer>
            <TopFlowContainer centerVContainerMaxHeight={"700px"}>
                <Title>You're all done!</Title>
                <ImageContainer>
                    <BannerImage src="https://echopapa.app/static/images/session-completed-1.png" alt="finish"/>
                </ImageContainer>
                <VSpace space={1}/>
                <SubTitle>You've completed your session for today. Let's review how you did.</SubTitle>
                <VSpace space={1}/>
                <StatsGrid>
                    <StatCard><StatTitle>Words Asked</StatTitle><StatValue>{props.wordsAsked}</StatValue></StatCard>
                    <StatCard><StatTitle>Correct</StatTitle><StatValue>{props.correct}</StatValue></StatCard>
                    <StatCard><StatTitle>Incorrect</StatTitle><StatValue>{props.incorrect}</StatValue></StatCard>
                    {props.hardestWord &&
                        <StatCard><StatTitle>Hardest
                            Word</StatTitle><StatValue
                            longContent={props.hardestWord.length > 12}>{props.hardestWord}</StatValue></StatCard>}
                </StatsGrid>
                {props.difficultWords.length > 0 &&
                    <DifficultWords>
                        <SubTitle>Words you found difficult</SubTitle>
                        <VSpace space={1}/>
                        {props.difficultWords.map((word, index) => (
                            <WordItem key={index}><span>{word.word}</span><strong>{word.count}</strong></WordItem>))}
                    </DifficultWords>
                }
                <MenuSecondaryButton onClick={() => route('/')}>Continue</MenuSecondaryButton>
            </TopFlowContainer>
        </ResponsiveContainer>
    );
}

export default FinishScreen;
