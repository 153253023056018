"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageLocaleToText = exports.BackendCreateAccountErrorMessages = exports.BackendLoginErrorMessages = void 0;
exports.BackendLoginErrorMessages = {
    UsernameOrEmailOrPhoneRequired: 'username or email or phone required',
    UsernameRequired: 'username required',
    InvalidUsername: 'invalid username',
    InvalidPassword: 'invalid password',
    UserDoesNotExist: 'user does not exist',
    UnknownError: 'unknown error',
};
exports.BackendCreateAccountErrorMessages = {
    InvalidUsername: 'invalid username',
    InvalidPassword: 'invalid password',
    UserAlreadyExists: 'username already exists',
    UnknownError: 'unknown error',
};
exports.LanguageLocaleToText = {
    'de-DE': 'German',
    'en-AU': 'Australian English',
};
// export const CountryCodeToText: Record<LanguageLocale, string> = {
//     'de-DE': 'German',
//     'en-GB': 'English',
//     'en-US': 'American English',
//     'en-AU': 'Australian English',
//     'es-ES': 'Spanish',
//     'fr-FR': 'French',
//     'it-IT': 'Italian',
//     'nl-NL': 'Dutch',
//     'pt-PT': 'Portuguese',
//     'ru-RU': 'Russian',
//     'tr-TR': 'Turkish',
//     'zh-CN': 'Chinese',
//     'ar-SA': 'Arabic',
//     'ja-JP': 'Japanese',
//     'ko-KR': 'Korean',
// }
