import {h} from "preact";
import {styled} from "goober";

const TaskHeadline = styled('h1')`
    font-size: 1.5rem;
    margin-bottom: 0px;
    `;

const ProgressBarContainer = styled('div')`
  background-color: #eee;
  border-radius: 10px;
  width: ${props => props.width}%;
  height: 20px;
  border: 1px solid lightgray;
`;

const ProgressBarFiller = styled('div')`
  background-color: #4caf50;
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
`;

const StatusBar = ({currentWord, amountWords, width}: {currentWord: number, amountWords: number, width: number}) => {
    const fillerWidth = amountWords > 0 ? `${(currentWord  / amountWords) * 100}%` : '0%';
    return (
        <div>
            <ProgressBarContainer width={width}>
                <ProgressBarFiller style={{ width: fillerWidth }} />
            </ProgressBarContainer>
            <TaskHeadline>Type what you hear</TaskHeadline>
        </div>
    );
};

export default StatusBar;
