import {Fragment, h} from 'preact';
import {GroupButton, MenuSecondaryButton} from "./ui/Button";
import {useTranslation} from "react-i18next";
import {ResponsiveContainer, TopFlowContainer} from "./ui/Container";
import {Title} from "./ui/Text";
import VSpace from "./ui/VSpace";
import {useState} from "preact/hooks";

const SchoolYearScreen = ({currentYear, onContinue}: {currentYear: number, onContinue: (year: number) => void}) => {
    const {t} = useTranslation();
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    return (
        <ResponsiveContainer>
            <TopFlowContainer centerVContainerMaxHeight={"700px"}>
                <Title>{t('settings.choose_school_year')}</Title>
                {[1,2,3,4,5,6,7,8,9,10,11,12].map((i) => {
                    return (
                        <Fragment key={i}>
                            <GroupButton selected={i === selectedYear} onClick={() => setSelectedYear(i)}>{`${t('school_year')} ${i}`}</GroupButton>
                            <VSpace/>
                        </Fragment>
                    )
                })}
                <VSpace space="2"/>
                <MenuSecondaryButton onclick={() => onContinue(selectedYear)}>{t('button_continue')}</MenuSecondaryButton>
            </TopFlowContainer>
        </ResponsiveContainer>
    );
};

export default SchoolYearScreen;
