import {Component, h} from 'preact';
import {styled} from 'goober';
import {ChangeEvent} from "preact/compat";
import {useEffect, useState} from "preact/hooks";

// https://www.jovidecroock.com/blog/controlled-inputs
// https://github.com/preactjs/preact/issues/3851#issuecomment-1371824709
// this is honestly legendary bullshit https://github.com/preactjs/preact/issues/3486

const Checkbox = styled('input')`
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
`;

const ControlledCheckbox = ({id, label, checked, onClick, disabled}: {
    id: string,
    label: string,
    checked: boolean,
    onClick: (state: boolean) => void
    disabled?: boolean
}) => {
    const handleOnClick = (e: ChangeEvent<HTMLInputElement>) => {
        const newChecked = !checked;
        e.preventDefault();
        setTimeout(() => {
            if (onClick) {
                onClick(newChecked);
            }
        });
    };
    return (
        <div>
            <Checkbox
                disabled={disabled}
                type="checkbox"
                id={id}
                checked={checked}
                onClick={handleOnClick}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default ControlledCheckbox;
