import {h, render} from 'preact';
import App from './App';
import {loadKatex} from "./lib/externalLibraryLoader";
import {preLoadGlobalAssets} from "./lib/globalAssets";
import "preact/debug";
import {setup} from 'goober';
import i18n from "./lib/i18n";
import i18next from "i18next";

const r = i18n();
console.log(r);

const tag = document.createElement("script");
tag.src = "https://kit.fontawesome.com/35a1eedcee.js";
tag.crossOrigin = "anonymous";
document.getElementsByTagName("head")[0].appendChild(tag);

function setFullHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setFullHeight);
setFullHeight();

function unregisterServiceWorker() {
    if (!navigator.serviceWorker) {
        return;
    }
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister().catch(function (err) {
                console.error(err);
            });
        }
    });
}

function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(
            new URL('service-worker.ts', import.meta.url),
            {type: 'module'})
            .then(function (registration) {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(function (error) {
                console.log('Service Worker registration failed:', error);
            });
        navigator.serviceWorker.ready.then((registration) => {
            registration.onupdatefound = function () {
                console.log('A new service worker is available, installing…');
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = function () {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            navigator.serviceWorker.ready.then((registration2) => {
                                registration2.update();
                            });
                            console.log('New service worker is available; please refresh.');
                        } else {
                            console.log('service worker is cached for offline use.');
                        }
                    }
                };
            }
        });
    }
}

registerServiceWorker();

console.log('index');

window.onload = () => {
    console.log('onload');
    render(<App/>, document.getElementById('root')!);
    loadKatex();
    preLoadGlobalAssets();
};



