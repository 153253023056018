import {styled} from "goober";

export const ResponsiveContainer = styled('div')`
    display: flex;
    flex-direction: column;

    margin: auto;
    max-width: 100vw;
    padding: 0 16px;
    @media ${props => props.theme.breakpoints.medium}, ${props => props.theme.breakpoints.large} {
        max-width: 500px;
    }
    
    height: 100%;
`;

export const TopFlowContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${props => props.theme.paper};
    min-height: 100%;
    @media (max-height: ${props => props.centerVContainerMaxHeight || '700px'}) {
        overflow-y: visible;
        min-height: auto;
    }
`;

export const CenterVHContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.paper};
    min-height: 100%;
    @media (max-height: ${props => props.centerVContainerMaxHeight || '700px'}) {
        overflow-y: visible;
        min-height: auto;
    }
`;

// the centerVContainerMaxHeight determines when the container flicks to not vertically centered
// and enable scroll

export const CenterVContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: ${props => props.theme.paper};
    min-height: 100%;
    @media (max-height: ${props => props.centerVContainerMaxHeight || '700px'}) { 
        overflow-y: visible;
        min-height: auto;
    }
`;
