import {h} from "preact";

import {styled} from 'goober';
import {useEffect, useRef} from "preact/hooks";
import {forwardRef} from 'preact/compat';

const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const height = '50px';

const Input = styled('input', forwardRef)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 58px);
  padding: 15px;
  margin: 0;
  border: 1px solid #ddd;
  border-right: none;
  border-radius: 15px 0 0 15px;
  font-size: 1rem;
  height: ${height};
`;

const SubmitButton2 = styled('button')`
  border: 1px solid #ddd;
  background-color: #1ff;
  border-radius: 0 15px 15px 0;
  `;

const SubmitButton = styled('button')`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 0 15px 15px 0;
  color: black;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${height};
  height: ${height};
  flex-basis: ${height};
`;

export interface SpellingInputProps {
    value: string;
    onChange: (newValue: string) => void;
    onSubmit: () => void;
    mobileDevice: boolean;
}

const SpellingInput = ({value, onChange, onSubmit, mobileDevice}: SpellingInputProps) => {
    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && !mobileDevice) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <InputWrapper>
            <Input
                ref={inputRef}
                type="text"
                spellcheck={false}
                value={value}
                onChange={(e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => onChange((e.target as HTMLInputElement).value)}
                onKeyDown={handleKeyPress}
                autocomplete="off"
                autocorrect={'off'}
                autocapitalize="off"
                readonly={mobileDevice}
            />
            <SubmitButton type="submit" onClick={onSubmit}>
                <i className="fa fa-arrow-right"></i>
            </SubmitButton>
        </InputWrapper>
    );
};

export default SpellingInput;
