import {Fragment, h} from "preact";
import {useEffect, useRef, useState} from "preact/hooks";
import {ApiClient} from "../api/realApiClient";
import {styled} from "goober";
import {route} from 'preact-router';
import {Button, MenuButton, MenuSecondaryButton, SecondaryButton} from "./ui/Button";
import {CenteredTitle, CenteredParagraph, Title} from "./ui/Text";
import {CenterVHContainer, ResponsiveContainer} from "./ui/Container";


const Footer = styled('footer')`
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 12px;
`;


const WelcomeScreen = () => {
    const onStart = (page: string, query: string) => {
        if (query === '') {
            route(page);
        } else {
            route(`${page}?${query}`);
        }
    };
    return (
        <Fragment>
            <ResponsiveContainer>
                <CenterVHContainer centerVContainerMaxHeight={"700px"}>
                    <CenteredTitle>
                        <span className="long-title">Welcome to EchoPapa</span>
                        <span className="short-title">EchoPapa</span>
                    </CenteredTitle>
                    <CenteredParagraph>
                        Which subject would you like to practice today?
                    </CenteredParagraph>
                    <MenuButton onClick={() => onStart('/maths', 'type=fraction')}>Mathematics</MenuButton>
                    <MenuButton onClick={() => onStart('/language', '')}>Language</MenuButton>
                    <MenuSecondaryButton onClick={() => onStart('/settings', '')}>Settings</MenuSecondaryButton>
                </CenterVHContainer>
            </ResponsiveContainer>
            <Footer>Made in Sydney</Footer>
        </Fragment>
    )
};

export default WelcomeScreen;
