import { h } from 'preact';
import { route } from 'preact-router';
import {authApiClient} from "../../globalDependencies";
import {useContext, useEffect, useState} from "preact/hooks";
import {GlobalContext, UserType} from "../../GlobalContext";
import {getTokenPayload} from "../../api/authApiClient";
import {areWeLoaded, registerLoadingFinished} from "../../lib/externalLibraryLoader";
import {AccessTokenPayload, IdTokenPayload, TokenRole} from '../../auth';

export const setUserFromToken = async (accessToken: string, idToken: string, setUser: (user: UserType) => void) => {
    const tokenPayload = getTokenPayload<AccessTokenPayload>(accessToken);
    const idPayload = getTokenPayload<IdTokenPayload>(idToken);
    setUser({
        subject: tokenPayload.sub,
        role: tokenPayload.role as TokenRole,
        nickname: idPayload.preferred_username
    });
}

// @ts-ignore
const withAuthWall = (WrappedComponent, requiresExternal?: boolean = false) => {
    // @ts-ignore
    return (props) => {
        const context = useContext(GlobalContext);
        const [externalLibrariesLoaded, setExternalLibrariesLoaded] = useState(areWeLoaded());

        useEffect(() => {
            registerLoadingFinished(() => {
                setExternalLibrariesLoaded(true);
            });
        }, []);

        const setUserInContext = async (): Promise<boolean> => {
            try {
                const accessToken = await authApiClient.getCurrentOrNewAccessToken();
                if (!accessToken) {
                    setUserInContextToNull();
                    return false;
                }
                const idToken = await authApiClient.getCurrentOrNewIdToken();
                await setUserFromToken(accessToken, idToken, context.setUser);
                return true;
            }
            catch(e) {
                console.error(e);
            }
            return false;
        }

        const setUserInContextToNull = () => {
            context.setUser(null);
        }

        useEffect(() => {
            if (!authApiClient.hasValidAccessToken()) {
                console.log('No valid access token, getting status');
                authApiClient.getAuthStatus().then((isAuthenticated) => {
                    console.log('Is authenticated:', isAuthenticated);
                    if (!isAuthenticated) {
                        setUserInContextToNull();
                        route('/login');
                    } else {
                        setUserInContext().then((success) => {
                            if (!success) {
                                route('/login');
                            }
                        });
                    }
                });
            } else {
                if (context.user === null) {
                    console.log('Setting user in context');
                    setUserInContext().catch((e) => {
                        console.error(e);
                    });
                }
            }

        }, []); // Dependencies array is empty, so this runs only once after the component mounts

        if (!externalLibrariesLoaded && requiresExternal) {
            return <div>Loading</div>;
        }
        return <WrappedComponent {...props} />;
    };
};

export default withAuthWall;
