import {styled} from "goober";

export const Title = styled('div')`
    font-size: 2rem;
    font-weight: bold;
    color: ${props => props.theme.pen};
    margin: 1rem 0;

    .long-title,
    .short-title {
        display: none;
    }

    @media ${props => props.theme.breakpoints.medium}, ${props => props.theme.breakpoints.large} {
        .long-title {
            display: block;
        }
    }

    @media ${props => props.theme.breakpoints.small} {
        .short-title {
            display: block;
        }
    }
`;

export const CenteredTitle = styled(Title)`
    text-align: center;
`;

export const SubTitle = styled('div')`
    font-weight: bold;
    color: ${props => props.theme.pen};
    margin: 0.5rem 0;
`;

export const CenteredSubTitle = styled(SubTitle)`
    text-align: center;
`;

export const CenteredParagraph = styled('p')`
    font-size: 1rem;
    margin-bottom: 2.5rem;
    text-align: center;
`;

export const Paragraph = styled('p')`
    font-size: 1rem;
    margin-bottom: 2.5rem;
`;
