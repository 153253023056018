const publicVapidKey = 'BAd8Y9Q41IEu5cHY3n9ljpUzA_ZrzqETUW8yA7ZHfdn8WYhrRJ9MRFEdVXaGXEVj_tvPxvURfRmhitkdlAm36IU';

const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const subscribeToPushNotifications = async () => {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        console.error('Push messaging is not supported');
        return;
    }
    try {
        const registration = await navigator.serviceWorker.ready;
        return await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        });
    } catch (error) {
        console.error('Error during push subscription', error);
    }
};
