import {styled} from "goober";
import {forwardRef} from "preact/compat";
import {h} from "preact";

export const BaseButton = styled('button', forwardRef)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.theme.pencil};
    border-radius: 20px;
    cursor: pointer;
    height: 3rem;
`;

export const Button = styled(BaseButton, forwardRef)`
    color: ${props => props.theme.paper};
    background: ${props => props.theme.pen};
`;

export const ContinueButton = styled(Button)`
    margin: 2rem 0 1rem 0;
    height: 3rem;
    padding: 0 3rem;
`;

export const SecondaryButton = styled(BaseButton, forwardRef)`
    color: ${(props) => props.theme.buttonTextColor};
    background: ${(props) => props.theme.pencil};
`;

export const MenuButton = styled(Button)`
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
`;

export const MenuSecondaryButton = styled(SecondaryButton)`
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
`;

export const GroupButton = styled(BaseButton, forwardRef)`
    color: ${props => props.selected ? props.theme.paper : props.theme.pen};
    background-color: ${props => props.selected ? props.theme.pen : "transparent"};
    transition: background-color 0.3s;
`;

const Icon = styled('i')`
`;

const ArrowIconMargin = styled(Icon)`
    margin-right: 1rem;
`;

export const BackIcon = ({onclick}: any) => <ArrowIconMargin className="fas fa-chevron-left" onclick={onclick}/>;
export const ForwardIcon = ({onclick}: any) => <Icon className="fas fa-chevron-right" onclick={onclick}/>;
