export const backendLoginErrorMessages = {
    usernameOrEmailOrPhoneRequired: 'username or email or phone required',
    usernameRequired: 'username required',
    invalidUsername: 'invalid username',
    invalidPassword: 'invalid password',
    userDoesNotExist: 'user does not exist',
    unknownError: 'unknown error',
};

export const backendCreateAccountErrorMessages = {
    invalidUsername: 'invalid username',
    invalidPassword: 'invalid password',
    userAlreadyExists: 'username already exists',
    unknownError: 'unknown error',
};

export type AccessTokenPayload = {
    sub: string;
    iss: string;
    aud: string;
    iat: number;
    exp: number;
    jti: string;
    role: string;
}

export type IdTokenPayload = {
    sub: string;
    iss: string;
    aud: string;
    iat: number;
    exp: number;
    jti: string;
    'preferred_username': string;
}

export type TokenRole = 'guest' | 'user' | 'admin';
