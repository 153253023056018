import {UserConfiguration} from "../GlobalContext";

const defaultConfiguration: UserConfiguration = {
    maths: {
        preferMultiplicationCrossOverDot: false,
        preferDivisionObelusOverSlash: false
    },
    schoolYear: 1,
    schoolCountry: 'de-DE'
}

const configurationItemKey = 'userConfiguration-vok';

export function loadUserConfiguration() : UserConfiguration {
    let configString = localStorage.getItem(configurationItemKey);
    if (!configString) {
        localStorage.setItem(configurationItemKey, JSON.stringify(defaultConfiguration));
        configString = localStorage.getItem(configurationItemKey);
    }
    if (!configString) {
        throw new Error('Failed to load user configuration');
    }
    return mergeConfigurations(defaultConfiguration, JSON.parse(configString));
}

function mergeConfigurations(defaultConfig: UserConfiguration, userConfig: UserConfiguration) {
    function mergeDeep(target: any, source: any) {
        if (typeof target == "object" && typeof source == "object") {
            for (const key in source) {
                if (source[key] === null) {
                    target[key] = null;
                    continue;
                }
                if (typeof source[key] == "object") {
                    if (!target[key]) target[key] = {};
                    mergeDeep(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }
        return target;
    }
    let cloneOfDefault = JSON.parse(JSON.stringify((defaultConfig)));
    return mergeDeep({...cloneOfDefault}, userConfig);
}

export function storeUserConfiguration(configuration: UserConfiguration) {
    localStorage.setItem(configurationItemKey, JSON.stringify(configuration));
}
