import {Fragment, h} from 'preact';
import {useTranslation} from "react-i18next";
import KatexComponent from "./KatexComponent";
import {styled} from "goober";
import {EchoPapaInputField} from "../ui/PaperInputField";
import {Title} from "../ui/Text";
import {ResponsiveContainer} from "../ui/Container";
import VSpace from "../ui/VSpace";
import {TextTask} from "vok-shared/dto/outgoing/maths";
import {ContinueButton, MenuSecondaryButton} from "../ui/Button";

type MathTextQuestionProps = {
    data: TextTask;
}

const MathsTextBlock = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: ${props => props.fontSize};
    line-height: calc(1.2 * ${props => props.fontSize});
`;

const AnotherStyledInput = styled('input')`
    font-weight: 400;
    font-size: ${props => props.fontSize};
    line-height: calc(1.2 * ${props => props.fontSize});
    margin: 0.5rem 0;
    background: ${props => props.theme.white};
    color: ${props => props.theme.pen};
    width: 90%;
    border: none;
    outline: none;
    border-bottom: 1px solid ${props => props.theme.pen};
`;

const MathTextQuestion = (props: MathTextQuestionProps) => {
    const {t} = useTranslation();

    const temp = props.data.textSelection.split('.');
    temp.pop();
    const titleKey = temp.join('.') + '.title';

    return (
        <Fragment>
            <ResponsiveContainer>
                <Title>{t(titleKey)}</Title>
                <MathsTextBlock fontSize={'1.2rem'}>{t(props.data.textSelection, props.data.textSubstitution)}</MathsTextBlock>
                <VSpace/>
                <EchoPapaInputField label={'Answer:'} CustomInputField={AnotherStyledInput} fontSize={'1.2rem'}
                                    type="text"/>
                <VSpace space={2}></VSpace>
                <MenuSecondaryButton>Submit</MenuSecondaryButton>
            </ResponsiveContainer>
        </Fragment>
    );
}

export default MathTextQuestion;
