import {Buffer} from "buffer";

export function deobfuscateSolution(obfuscatedSolution: string, salt: string) {
    const decoded = deobfuscateSolutionWithXOR(Buffer.from(obfuscatedSolution, 'base64').toString('utf-8'), salt);
    const lengthOfSolution = parseInt(decoded.substring(0, 3), 10);
    return decoded.substring(3, 3 + lengthOfSolution);
}

function xorUnscramble(str: string, key: string) {
    let result = '';
    for (let i = 0; i < str.length; i++) {
        result += String.fromCharCode(str.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return result;
}

function deobfuscateSolutionWithXOR(obfuscatedSolution: string, salt: string) {
    const scrambledSolution = Buffer.from(obfuscatedSolution, 'base64').toString('utf-8');
    return xorUnscramble(scrambledSolution, salt);
}
