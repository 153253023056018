function loadScript(url: string, async = true, callbackName = '') {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = async;

        if (callbackName) {
            (window as any)[callbackName] = () => resolve(script);
        } else {
            script.onload = () => resolve(script);
        }
        script.onerror = (err) => reject(new Error(`Script load error for ${url} ${err}`));
        document.head.appendChild(script);
    });
}

// Usage
function loadCSS(url: string) {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        link.integrity = 'sha384-n8MVd4RsNIU0tAv4ct0nTaAbDJwPJzDEaqSD1odI+WdtXRGWt2kTvGFasHpSy3SV';
        link.crossOrigin = 'anonymous';

        link.onload = () => resolve(link); // Resolve the promise once the CSS is loaded
        link.onerror = (err) => reject(new Error(`CSS load error for ${url} ${err}`)); // Reject the promise if there's an error
        document.head.appendChild(link); // Append the link to the document
    });
}

let weAreLoaded = false;

export const areWeLoaded = () => weAreLoaded;

export async function loadKatex() {
    if (weAreLoaded) {
        return;
    }
    if (!document.querySelector('script[src="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.js"]')) {
        await loadScript("https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.js");
        console.log('katex loaded');
    }
    if (!document.querySelector('link[href="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css"]')) {
        await loadCSS("https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css");
        console.log('katex css loaded');
    }
    if (!document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
        await loadScript("https://accounts.google.com/gsi/client", true, 'onGoogleLibraryLoad');
        console.log('google gsi loaded');
    }
    if (!document.querySelector('script[src="https://cdnjs.cloudflare.com/ajax/libs/bcryptjs/2.4.3/bcrypt.min.js"]')) {
        await loadScript("https://cdnjs.cloudflare.com/ajax/libs/bcryptjs/2.4.3/bcrypt.min.js", true);
    }
    weAreLoaded = true;
    // simulate this takes very long
    // todo remove listener
    setTimeout(() => {
        window.dispatchEvent(new Event('externalLibrariesLoaded'));
    }, 1);
}

export const registerLoadingFinished = (handler: () => void) => {
    if (weAreLoaded) {
        handler();
    }
    window.addEventListener('externalLibrariesLoaded', handler);
}
