import {Fragment, h} from 'preact';

import {useEffect, useState, useRef, useContext} from 'preact/hooks';

import {styled} from 'goober';
import {ApiClient} from "../../api/realApiClient";
import {ContinueButton, GroupButton, MenuSecondaryButton} from "../ui/Button";
import {useTranslation} from "react-i18next";
import {ResponsiveContainer, TopFlowContainer} from "../ui/Container";
import {Title} from "../ui/Text";
import {route} from "preact-router";
import VSpace from "../ui/VSpace";
import {GlobalContext} from "../../GlobalContext";

const MathsMenuScreen = () => {
    //const [keywords, setKeywords] = useState<string[]>([]);
    const userConfiguration = useContext(GlobalContext).userConfiguration;
    const {t} = useTranslation();

    const onContinue = () => {
        route('/maths/questions');
    }

    const mapdeDeSchoolYearToSubjects: Record<number, string[]> = {
        1: [ 'Textaufgabe', 'Subtraction', 'Multiplication', 'Division' ],
    }

    const mapCountryCodeToContent: Record<string, Record<number, string[]>> = {
        "de-DE": mapdeDeSchoolYearToSubjects,
        "en-GB": mapdeDeSchoolYearToSubjects,
        "en-US": mapdeDeSchoolYearToSubjects
    }

    console.log('rendering MathsMenuScreen');

    return (
        <ResponsiveContainer>
            <TopFlowContainer>
            <Title>{t('maths.title')}</Title>
                {mapCountryCodeToContent[userConfiguration.schoolCountry][userConfiguration.schoolYear].map((i) => {
                    return (
                        <Fragment key={i}>
                        <GroupButton>{`${i}`}</GroupButton>
                            <VSpace/>
                        </Fragment>
                    )
                })}
                <VSpace space="2"/>
                <MenuSecondaryButton onclick={() => onContinue()}>{t('button_continue')}</MenuSecondaryButton>
            </TopFlowContainer>
        </ResponsiveContainer>
    );
};

export default MathsMenuScreen;
