export const apiBaseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

type AssetType = 'success' | 'failure';

let cachedAssets: Partial<Record<AssetType, HTMLAudioElement>> = {};
let allAssetsLoaded = false;

function isAudioPlaying(audioElement: HTMLAudioElement) {
    return audioElement && !audioElement.paused && !audioElement.ended && audioElement.currentTime > 0;
}

function resetAndPlayAudio(audioElement: HTMLAudioElement) {
    if (!audioElement) return;

    audioElement.pause();      // Pause the audio
    audioElement.currentTime = 0; // Reset the playback position
    audioElement.play();       // Start playing the audio again
}

export function preLoadGlobalAssets() {
    if (allAssetsLoaded) return;
    setTimeout(() => {
        const successSound = new Audio(`${apiBaseUrl}/static/dictate/success.mp3`);
        successSound.load();
        cachedAssets['success'] = successSound;
        const failureSound = new Audio(`${apiBaseUrl}/static/dictate/failure.mp3`);
        failureSound.load();
        cachedAssets['failure'] = failureSound;
        allAssetsLoaded = true;
    }, 1);
}

const getAsset = (assetType: AssetType): HTMLAudioElement => {
    const asset = cachedAssets[assetType];
    if (!asset) {
        throw new Error(`Asset ${assetType} not found`);
    }
    return asset;
}

export const playAudio = (assetType: AssetType) => {
    const asset = getAsset(assetType);
    if (isAudioPlaying(asset)) {
        resetAndPlayAudio(asset);
    } else {
        asset.play();
    }
}

