import {createContext} from "preact";
import {TFunction} from "i18next";
import {TokenRole} from './auth';

export type UserType = {
    subject: string;
    nickname: string;
    role: TokenRole;
}

export type UserMathsConfiguration = {
    preferMultiplicationCrossOverDot: boolean;
    preferDivisionObelusOverSlash: boolean;
}

export type UserConfiguration = {
    schoolYear: number;
    schoolCountry: string;
    maths: UserMathsConfiguration;
}

export type GlobalContextType = {
    isDebug: boolean;
    isMobileDevice: boolean;
    hasPhysicalKeyboard: boolean;
    timeZone: string;
    user: UserType | null;
    setUser: (user: UserType | null) => void;
    userConfiguration: UserConfiguration;
}

export const GlobalContext = createContext<GlobalContextType>(null as unknown as GlobalContextType);
