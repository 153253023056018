import {styled} from "goober";
import {fontSizes} from "../../styles/typography";
import {Fragment, h} from "preact";
import {useContext, useState} from "preact/hooks";
import {GlobalContext} from "../../GlobalContext";
import {forwardRef} from "preact/compat";

let idCounter = 0;

const generateId = () => {
    return `echo-papa-input-${idCounter++}`;
};

const EchoPapaInputFieldStyled = styled('input', forwardRef)`
    font-size: ${fontSizes.normal};
    margin: 0.5rem 0;
    background: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.pen};
    padding: 0.75rem;
    width: 90%;
`;

const Label = styled('label')`
    font-size: ${props => props.fontSize};
    display: block;
    margin-bottom: 0.5rem;
`;

const handleFocus = (event: any) => {
    setTimeout(() => {
        event.target.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, 300);
};

export const EchoPapaInputField = ({label, id, onEnterPress, CustomInputField, ...props}: any) => {

    const [inputId] = useState(props.id || generateId());

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && onEnterPress) {
            onEnterPress();
        }
    };

    const InputField = CustomInputField || EchoPapaInputFieldStyled;

    const glob = useContext(GlobalContext);
    if (glob.isMobileDevice) {
        return (
            <Fragment>
                {label && <Label htmlFor={inputId} {...props}>{label}</Label>}
            <InputField {...props} id={inputId} onKeyPress={handleKeyPress} onfocus={handleFocus} autocomplete={'off'}/>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                {label && <Label htmlFor={inputId}>{label}</Label>}
            <InputField {...props} id={inputId} onKeyPress={handleKeyPress} autocomplete={'off'}/>
            </Fragment>);
    }
};
