import {Fragment, h} from 'preact';

import {styled} from "goober";
import {ApiClient} from "../api/realApiClient";
import {useEffect, useState} from "preact/hooks";
import {UserActivity} from "vok-shared/dto/outgoing/activity";
import {LanguageLocaleToText} from "vok-shared";
import {Title} from "./ui/Text";
import {route} from "preact-router";
import {BackIcon} from "./ui/Button";

const convertUtcToTimezone = (utcDate: Date, timeZone: string) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: timeZone
    };

    return new Intl.DateTimeFormat(undefined, options as any).format(utcDate);
}

const Header = styled('div')`
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 18px;
    border-bottom: 1px solid #e6e6e6;
`;

const GridContainer = styled('div')`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(6, auto);
    border-collapse: collapse;
`;

const GridHeader = styled('div')`
    padding: 12px 16px;
    background: #f0f0f0;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #ddd;
`;

const GridItem = styled('div')`
    display: contents;
    white-space: nowrap;
`;

const GridCell = styled('div')`
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;

    &:nth-child(odd) {
        background-color: #fcfcfc;
    }
`;


const ActivityScreen = ({username}: any) => {
    const [activities, setActivities] = useState<UserActivity[]>([]);
    const [error, setError] = useState<string | null>(null);
    const populateActivities = async () => {
        try {
            const result = await ApiClient.getActivities(username);
            setActivities(result);
        }
        catch (e) {
            console.error(e);
            setError('Failed to load activities');
        }
    }

    useEffect(() => {
        populateActivities();
    }, []);

    return (
        <Fragment>
        <Header><BackIcon onclick={() => route('/')}/>
            <Title>Activities for {username}</Title></Header>
            {error && <div>{error}</div>}
            <GridContainer>
                <GridItem>
                    <GridHeader>Start</GridHeader>
                    <GridHeader>End</GridHeader>
                    <GridHeader>Language</GridHeader>
                    <GridHeader>Level</GridHeader>
                    <GridHeader>Word Type</GridHeader>
                    <GridHeader>Words Answered</GridHeader>
                </GridItem>
                {activities.map((activity, index) => (
                    <GridItem key={activity.sessionUuid} index={index}>
                        <GridCell>{convertUtcToTimezone(new Date(activity.sessionStart), activity.timezone)}</GridCell>
                        <GridCell>{activity.sessionEnd ? convertUtcToTimezone(new Date(activity.sessionEnd), activity.timezone) : 'not finished'}</GridCell>
                        <GridCell>{activity.language ? LanguageLocaleToText[activity.language] : 'unknown'}</GridCell>
                        <GridCell>{activity.level}</GridCell>
                        <GridCell>{activity.wordType}</GridCell>
                        <GridCell>{activity.wordsAnswered}/{activity.wordsAsked}</GridCell>
                    </GridItem>
                ))}
            </GridContainer>
        </Fragment>
    );
}

export default ActivityScreen;
