import MathFormulaQuestion from "./MathFormulaQuestion";

import {Fragment, h} from 'preact';
import {useContext, useEffect, useRef, useState} from "preact/hooks";
import {ApiClient} from "../../api/realApiClient";
import {GlobalContext} from "../../GlobalContext";
import MathTextQuestion from "./MathTextQuestion";

const client = ApiClient;

type QuestionFromServer = {
    id: string;
    keywords: string;
    latex: string;
    solution: string[];
    data: any;
}

export const MathQuestionsSet = () => {
    const configuration = useContext(GlobalContext).userConfiguration.maths;
    const [questions, setQuestions] = useState<QuestionFromServer[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(null);
    const currentQuestionIndexRef = useRef(currentQuestionIndex);

    const convertLatexToUserPreference = (latex: string) => {
        if (!latex) return;
        if (configuration.preferMultiplicationCrossOverDot) {
            latex = latex.replace(/\\cdot/g, '\\times');
        } else {
            latex = latex.replace(/\\times/g, '\\cdot');
        }
        if (!configuration.preferDivisionObelusOverSlash) {
            latex = latex.replace(/\\div/g, '/');
        }
        return latex;
    }

    const loadQuestions = async () => {
        const questions = await client.getMathsQuestions('ARITHMETIC,TEXT,ADDITION,2NUMBERS,MAX10');
        setQuestions(questions.map((q: QuestionFromServer) => {
            return {
                id: q.id,
                data: q.data,
                keywords: q.keywords,
                latex: convertLatexToUserPreference(q.latex),
                solution: q.solution
            }
        }));
        setCurrentQuestionIndex(0);
    }

    useEffect(() => {
        loadQuestions();
    }, []);

    // Update the ref whenever the state changes
    useEffect(() => {
        currentQuestionIndexRef.current = currentQuestionIndex;
    }, [currentQuestionIndex]);

    const handleSubmit = (answer: string[]) => {
        if (currentQuestionIndexRef.current === null) {
            return;
        }
        // Use the current value from the ref
        if (currentQuestionIndexRef.current < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndexRef.current + 1);
        }
    };

    return (
        <Fragment>
            {/*{currentQuestionIndex !== null && <MathFormulaQuestion solution={questions[currentQuestionIndex].solution}*/}
            {/*                                                       task={questions[currentQuestionIndex].keywords}*/}
            {/*                                                       latexFormula={questions[currentQuestionIndex].latex}*/}
            {/*                                                       onSubmit={handleSubmit}*/}
            {/*/>}*/}
            {currentQuestionIndex !== null && <MathTextQuestion data={questions[currentQuestionIndex!].data} />}
        </Fragment>
    );
}
