import {styled} from "goober";
import {h} from "preact";
import {EchoPapaInputField} from "../ui/PaperInputField";
import {useContext, useState} from "preact/hooks";
import {ContinueButton} from "../ui/Button";
import {authApiClient} from "../../globalDependencies";

import * as bcryptjs from "bcryptjs";
import {route} from "preact-router";
import {GlobalContext} from "../../GlobalContext";
import {CenterVHContainer, ResponsiveContainer} from "../ui/Container";
import {Title} from "../ui/Text";
import {CreateAccountFailReason} from "../../api/authApiClient";

declare global {
    var dcodeIO: {
        bcrypt: typeof bcryptjs;
    };
}

export const clientSideTomCoreSalt = "$2a$10$QjhNo9WOkzIKXFtN12C0YO";

export function extractHash(bcryptHash: string) {
    const parts = bcryptHash.split('$');
    if (parts.length !== 4) {
        throw new Error('Invalid bcrypt hash');
    }
    // The hash is the part after the salt (the fourth part of the string, after splitting)
    // Since the salt is 22 characters long, we take the substring from the 29th character to the end
    // 29 is calculated as: length of the prefix ($2b$10$) + 22 characters of salt
    return parts[3].substring(22);
}

const FrameLogin = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

const Welcome = styled('div')`
    margin: 2.5rem 0 2.5rem 0;
    font-size: 2rem;
    font-weight: bold;
`;

const CreateAccount = () => {

    const {setUser} = useContext(GlobalContext);

    const createAccount = async () => {
        try {
            if (nickName.length < 3) {
                setNameError(true);
                return;
            }
            if (password !== password2) {
                setPasswordError(true);
                return;
            }
            setNameError(false);
            setPasswordError(false);
            var hashedPassword = extractHash(dcodeIO.bcrypt.hashSync(password, clientSideTomCoreSalt));
            setPassword('');
            setPassword2('');
            const result = await authApiClient.createAccount({username: nickName, password: hashedPassword});
            if (result.failedReason) {
                if (result.failedReason === CreateAccountFailReason.InvalidPassword) {
                    setPasswordError(true);
                } else if (result.failedReason === CreateAccountFailReason.InvalidUsername) {
                    setNameError(true);
                } else if (result.failedReason === CreateAccountFailReason.UserAlreadyExists) {
                    setNameError(true);
                }
                return;
            }
            setUser(null);
            route('/');
        } catch (e) {
            console.error(e);
        }
    }

    const [nickName, setNickName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [nameError, setNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    return <ResponsiveContainer>
        <CenterVHContainer centerVContainerMaxHeight={"700px"}>
        <Title>Create Account</Title>
        <EchoPapaInputField
            style={{border: nameError ? '2px solid red' : '1px solid #d6d6d6'}}
            type="text"
            onInput={(e: any) => setNickName(e.currentTarget.value)}
            value={nickName}
            placeholder="username"/>
        <EchoPapaInputField
            style={{border: passwordError ? '2px solid red' : '1px solid #d6d6d6'}}
            type="password"
            onInput={(e: any) => setPassword(e.currentTarget.value)}
            value={password}
            placeholder="password"/>
        <EchoPapaInputField
            style={{border: passwordError ? '2px solid red' : '1px solid #d6d6d6'}}
            type="password"
            onInput={(e: any) => setPassword2(e.currentTarget.value)}
            value={password2}
            placeholder="repeat password"/>
        <ContinueButton onClick={createAccount}>Sign up</ContinueButton>
        </CenterVHContainer>
    </ResponsiveContainer>;
}

export default CreateAccount;
